import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getUserProperty } from '../../../../utils';
import { DatePicker } from '../../formV3/controllers';
import MedicTeamsOptions from '../../formV3/controllers/InputSelect/Options/MedicTeamsOptions';
import InputSelectV2 from '../../formV3/controllers/InputSelectV2';
import { CustomFormController } from '../../formV3/innerWrapper';
import ModalButtonsGroup from '../../formV3/submit/ModalButtonsGroup';
import { INITIAL_SURVEY_FORM } from './FormModel/formInitialValues';
import yupSchema from './FormModel/validationSchema';

export const userToOptions = u => ({
  isOwner: u && u.isOwner,
  value: u && u.id,
  label: u && getUserProperty(u, 'titledName'),
});


const SurveyPatientForm = ({
  id = 'surveyPatientForm',
  medicTeamOptions,
  updateData,
  defaultValues = INITIAL_SURVEY_FORM,
  onSubmit,
  onCancel,
  clinicsListOptions,
  patientsListOptions,
  patientDetailRequest,
}) => {
  const { t } = useTranslation();
  const [medicSurveysOptions, setMedicSurveysOptions] = useState([]);
  const [referentMedicOptions, setReferentMedicOptions] = useState([]);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    resolver: yupResolver(yupSchema),
    shouldFocusError: true,
  });
  const { control, watch, handleSubmit, setValue, formState: { errors } } = formMethods;
  const medicTeamIdWatch = useWatch({ control, name: 'medical_teams_id' });
  const medicSurveysWatch = useWatch({ control, name: 'medic_surveys' });
  const referentMedicWatch = useWatch({ control, name: 'referent_medic_id' });

  useEffect(() => {
    formMethods.reset({ ...defaultValues });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    const newMedicSurveys = (medicSurveysOptions || []).map(el => el?.value);
    setValue('medic_surveys', newMedicSurveys || []);
  }, [medicSurveysOptions]);

  const setReferentMedic = (referentMedicOptions) => {
    if (!referentMedicWatch || referentMedicWatch === '') {
      const owner = (referentMedicOptions || []).find(m => m.isOwner);
      if (owner && owner.value){
        // no referent medic but medic onwner inside medics
        setValue('referent_medic_id', owner?.value);
      } else if (Array.isArray(medicSurveysWatch) && medicSurveysWatch.length === 1) {
        // no referent medic but 1 medic selected in team
        const medic = (referentMedicOptions || []).find(m => m.value === medicSurveysWatch[0]);
        setValue('referent_medic_id', medic?.value);
      } else {
        setValue('referent_medic_id', '');
      }
    } else {
      // already a referentMedicWatch selected
      if (!Array.isArray(medicSurveysWatch) || !medicSurveysWatch.includes(referentMedicWatch)) {
        // if referent_medic_id is not listed into medicSurveys
        setValue('referent_medic_id', '');
      }
    }
  };

  useEffect(() => {
    if (defaultValues.medical_teams_id) {
      const medicSurveyList = getMedicSurveysOptions(defaultValues.medical_teams_id);
      getReferentMedicOptions(medicSurveyList, defaultValues.medical_teams_id);
    }
  }, [defaultValues?.medical_teams_id, medicTeamOptions]);

  useEffect(() => {
    getMedicSurveysOptions(medicTeamIdWatch);
  }, [medicTeamIdWatch]);

  useEffect(() => {
    const referentMedicOptions = getReferentMedicOptions(medicSurveysWatch, medicTeamIdWatch);
    setReferentMedic(referentMedicOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicSurveysWatch]);

  const onSubmits = (data) => {
    if (data?.patient_id){
      patientDetailRequest(data?.patient_id);
    }
    if (updateData) updateData(id, data);
    if (onSubmit) onSubmit(null, data);
  };

  // eslint-disable-next-line no-console
  const onErrors = (errors) => console.error('errors :>> ', errors);

  const handleCancel = (id) => {
    if (onCancel) onCancel(id);
  };

  const getMedicSurveysOptions = value => {
    const selectedTeam = medicTeamOptions.find(mt => mt.value === value);
    const newMedicSurveysOptions = (selectedTeam?.medics || [])
      .filter(m => m.is_secretary === false)
      .map(m => userToOptions(m));
    // set medic_surveys value
    setMedicSurveysOptions(newMedicSurveysOptions);
    return newMedicSurveysOptions;
  };

  const getReferentMedicOptions = (value, medicTeamId) => {
    const selectedTeam = medicTeamOptions.find(el => el.value === medicTeamId);
    const newReferentMedicOptions = (selectedTeam?.medics || [])
      .filter(m => m.is_secretary === false && (value || []).includes(m.id))
      .map(m => userToOptions(m));

    setReferentMedicOptions(newReferentMedicOptions);
    return newReferentMedicOptions;
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmits, onErrors)} className={'custom-formV2 d-flex flex-column'} >
        <div className="p-2">
          <CustomFormController
            name={'patient_id'}
            label={t('surveyPatientForm.patient_id.label')}
            isRequiredField={true}
          >
            <InputSelectV2 options={patientsListOptions} />
          </CustomFormController>
        </div>
        <div className="p-2">
          <CustomFormController
            name={'clinic_id'}
            label={t('surveyPatientForm.clinic_id.label')}
            isRequiredField={true}
          >
            <InputSelectV2 options={clinicsListOptions} />
          </CustomFormController>
        </div>
        <div className="p-2">
          <CustomFormController
            name={'medical_teams_id'}
            label={t('surveyPatientForm.medical_teams_id.label')}
            isRequiredField={true}
          >
            <InputSelectV2
              options={medicTeamOptions}
              components={ { Option:  props => <MedicTeamsOptions {...props} /> }}
              control={control}
            />
          </CustomFormController>
        </div>
        <div className="p-2">
          <CustomFormController
            name={'medic_surveys'}
            label={t('surveyPatientForm.medic_surveys.label')}
            isRequiredField={true}
          >
            <InputSelectV2
              options={medicSurveysOptions}
              isMulti
            />
          </CustomFormController>
        </div>
        <div className="p-2">
          <CustomFormController
            name={'referent_medic_id'}
            label={t('surveyPatientForm.referent_medic_id.label')}
            isRequiredField={true}
          >
            <InputSelectV2 options={referentMedicOptions} />
          </CustomFormController>
        </div>
        <div className="d-flex flex-sm-row flex-column align-items-end">
          <div className="p-2 w-100 w-sm-50">
            <CustomFormController
              name={'intervention.date_consult'}
              label={t('surveyPatientForm.intervention.date_consult.label')}
              isRequiredField={true}
            >
              <DatePicker inputPreset={'intervention_date'} />
            </CustomFormController>
          </div>
          <div className="p-2 w-100 w-sm-50">
            <CustomFormController
              name={'intervention.date'}
              label={t('surveyPatientForm.intervention.date.label')}
              isRequiredField={true}
            >
              <DatePicker inputPreset={'intervention_date'} />
            </CustomFormController>
          </div>
        </div>
        <ModalButtonsGroup onCancel={handleCancel} submitLabel={t('form.button.next.label')} />
      </form>
      {!!process.env.REACT_APP_DEBUG_FORM && (
        <pre>
          { JSON.stringify(watch(), null, 2) }
          { JSON.stringify(errors, null, 2) }
        </pre>
      )}
    </FormProvider>
  );
};

SurveyPatientForm.propTypes = {
  id: PropTypes.string, schemaValidation:PropTypes.func,
  updateData: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SurveyPatientForm;
