import { connect } from 'react-redux';

import Item from './Item';

const mapStateToProps = ({ medic, auth }) => {
  return {
    medic: medic.currentMedic,
    languageCode: auth.user.language.language_code,
  };
};
export default connect(mapStateToProps)(Item);
