import './style.css';

import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AbilityContext } from '../../../services/casl/Can';
import { HOME_MODULES, MODEL_NAMES, PERMISSIONS } from '../../../services/casl/roles';
import { SURVEY_STATUS, surveyStatusMapper as statusMapper } from '../../../utils';
import { SURVEY_STATUS_COLOUR } from '../../../utils/enum';
import { filterAndOrderList } from '../../../utils/format';
import { extractParams } from '../../../utils/router-helper';
import { isNullOrWhitespace } from '../../../utils/utils';
import AuthLayout from '../../layouts/authLayout';
import List from './list';
import SideMenu from './menu';
import { FILTER_MAPPER } from './menu/FilterCard';

export const sortByFamilyName = (a, b) => {
  const nameA = (a.use_name && a.use_name !== '' ? a.use_name : a.lastname)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const nameB = (b.use_name && b.use_name !== '' ? b.use_name : b.lastname)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return nameA.localeCompare(nameB);
};

const INITIAL_MEDIC_METRICS = {
  surgery: {
    id: FILTER_MAPPER.SURGERY,
    value: FILTER_MAPPER.SURGERY,
    number: 0,
    patientIdList: null,
  },
  consent: {
    id: FILTER_MAPPER.CONSENT,
    value: FILTER_MAPPER.CONSENT,
    number: 0,
    patientIdList: null,
  },
  patient: {
    id: FILTER_MAPPER.PATIENT,
    value: FILTER_MAPPER.PATIENT,
    number: 0,
    patientIdList: null,
  },
};

const Medic = ({
  deleteTemporarySurvey,
  patients,
  initialSurveyList,
  temporarySurvey,
  activeFilter,
  setActiveFilter,
  medic,
  selectedTeamId,
  isClinicalMode,
  surveyTypes,
  clearSelectedPatient,
  clearData,
}) => {
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const sideMenuRef = useRef({});
  const [isLoading, setIsLoading] = useState(true);
  const [searched, setSearched] = useState();
  const [urlParams, setUrlParams] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setUrlParams(extractParams(searchParams));
  }, [searchParams]);

  const action = useMemo(() => urlParams?.action, [urlParams?.action]);

  useEffect(() => {
    // remove selectedPatient from Redux store
    clearSelectedPatient();
  }, []);

  useEffect(() => {
    resetSearchBar();
    setSearched();
  }, [selectedTeamId]);

  useEffect(() => {
    // open consent modale if action = consent in url
    if (action && action === FILTER_MAPPER.CONSENT) {
      if (sideMenuRef && sideMenuRef.current) {
        if (sideMenuRef.current.isOpen() === false)
          sideMenuRef.current.openModalForm();
      }
    }
  }, [action, sideMenuRef && sideMenuRef.current]);

  const typeIdsWithCountersign = useMemo(() => {
    return Array.isArray(surveyTypes) ? surveyTypes.filter(st => st.should_countersign).map(st => st.id) : [];
  }, [surveyTypes]);

  const medicMetrics = useMemo(() => {
    // metrics are the number consents, number inter, number patient
    return getMedicMetrics(patients, initialSurveyList, isClinicalMode);
  }, [
    JSON.stringify(patients),
    JSON.stringify(initialSurveyList),
    isClinicalMode,
  ]);

  const patientIdList = useMemo(() => {
    // metrics are the number consents, number inter, number patient
    return _.get(medicMetrics, [activeFilter, 'patientIdList'], []);
  }, [
    JSON.stringify(medicMetrics),
    activeFilter,
  ]);

  const displayedList = useMemo(() => {
    const filteredList = (patientIdList || []).map(id => patients.find(p => p.id === id));
    const searchList = filterAndOrderList(filteredList, searched);
    if (Array.isArray(patientIdList)) {
      setIsLoading(false);
    }
    return searchList;
  }, [activeFilter, JSON.stringify(patientIdList), JSON.stringify(patients), JSON.stringify(searched)]);

  const filteredPatientsSurveys = useMemo(() => {
    return Array.isArray(patientIdList) && Array.isArray(initialSurveyList)
      ? initialSurveyList.filter(s => patientIdList.includes(s.patient_id))
      : [];
  }, [patientIdList, JSON.stringify(initialSurveyList)]);

  const statValues = useMemo(() => {
    // stats valus is percentage on the home page
    return getStatValues(filteredPatientsSurveys, isClinicalMode, typeIdsWithCountersign);
  }, [JSON.stringify(filteredPatientsSurveys), isClinicalMode, typeIdsWithCountersign]);

  const handleSearch = (filters) => {
    const newSearch = Array.isArray(filters) ? filters.map((f) => f.id) : null;
    if (!_.isEqual(newSearch, searched)) {
      // (newSearch && (Array.isArray(newSearch) && newSearch.length)) && setIsLoading(true);
      setSearched(!filters ? null : filters.map((f) => f.id));
    }
  };

  const handleFilter = (filter) => {
    if (!_.isEqual(filter, activeFilter)) {
      setIsLoading(true);
      setActiveFilter(filter);
    }
  };

  const resetSearchBar = () => {
    if (sideMenuRef?.current?.resetSearchBar) {
      sideMenuRef.current.resetSearchBar();
    }
  };

  const navigateToMedicalTeamStore = () => {
    navigate(`/teams/${selectedTeamId}/forms`);
  };

  const navigateToPatient = (id) => {
    navigate(`/teams/${selectedTeamId}/patients/${id}`);
  };

  return (
    <AuthLayout>
      <div className={'medic-screen'}>
        { ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.HOME_MODULES, value: HOME_MODULES.SIDE_MENU }) && (
          <SideMenu
            ref={sideMenuRef}
            onFilter={handleFilter}
            deleteTemporarySurvey={deleteTemporarySurvey}
            onForm={navigateToMedicalTeamStore}
            onSearch={handleSearch}
            patientsList={displayedList || []}
            temporarySurvey={temporarySurvey}
            selectedFilter={activeFilter}
            medicMetrics={medicMetrics || INITIAL_MEDIC_METRICS}
            statValues={statValues}
            isSelectedTeam={!isNullOrWhitespace(`${selectedTeamId}`)}
            isClinicalMode={isClinicalMode}
            clearData={clearData}
          />
        )}
        { ability.can(PERMISSIONS.READ, { __typename: MODEL_NAMES.HOME_MODULES, value: HOME_MODULES.PATIENT_LIST }) && (
          <List
            patientsList={displayedList || []}
            selectedFilter={activeFilter}
            isListLoading={ isLoading}
            onClick={navigateToPatient}
            selectedTeam={selectedTeamId}
            medicId={medic && medic.id}
            isClinicalMode={isClinicalMode}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Medic;



const getMedicMetrics = (filteredPatientsList, filteredPatientsSurveys, isClinicalMode) => {
  // intervention == surgery == les cst devraient se trier par date chronologiquement. Les interventions les plus proches apparaissent en haut, les plus lointaines en bas
  // consentement == consent == les cst devraient se trier par état de cst. Rouge en premier, puis jaune, puis bleu
  // consent == consentement
  if (!filteredPatientsList.length) {
    return null;
  }
  const patientListWithSurveys = filteredPatientsList.filter(p => Array.isArray(p.surveys) && p.surveys.length);


  const dateInterventionFilter = (s) => s.intervention.date && new Date(s.intervention.date).getTime() >= new Date().getTime();

  let consentPatients = [...patientListWithSurveys]
    .map((p) => ({
      ...p,
      surveys: p.surveys.sort((a, b) => statusMapper(_.get(b, 'status'), isClinicalMode) - statusMapper(_.get(a, 'status'), isClinicalMode)),
    }))
    .filter(p => p.surveys.filter(dateInterventionFilter).length > 0)
    .sort((a, b) => new Date(_.get(a, 'surveys.0.intervention.date')) - new Date(_.get(b, 'surveys.0.intervention.date')))
    .sort((a, b) => statusMapper(_.get(b, 'surveys.0.status'), isClinicalMode) - statusMapper(_.get(a, 'surveys.0.status'), isClinicalMode))
    .map(p => p.id);
  // const consentPatients = _.uniq(filteredPatientsSurveys.map(s => s.patient_id));

  const surgeryNumbers = _.uniq(filteredPatientsSurveys.reduce((acc, s) => (s ? [...acc, s.intervention] : acc), []).map(s => s && s.number));
  const surgeryPatients = patientListWithSurveys.length
    ? [...patientListWithSurveys]
      .filter(p => p.surveys.filter(dateInterventionFilter).length > 0)
      .sort((a, b) => new Date(_.get(a, 'surveys.0.intervention.date')) - new Date(_.get(b, 'surveys.0.intervention.date')))
      .map(s => s.id)
    : [];
  // patient == patient
  const sortedPatientList = [...filteredPatientsList].sort(sortByFamilyName);
  const newPatient = sortedPatientList.filter((p) => p.isNew);
  const notNewPatient = sortedPatientList.filter((p) => !p.isNew);

  return {
    surgery: {
      id: FILTER_MAPPER.SURGERY,
      value: FILTER_MAPPER.SURGERY,
      number: surgeryNumbers.length || 0,
      patientIdList: surgeryPatients,
    },
    consent: {
      id: FILTER_MAPPER.CONSENT,
      value: FILTER_MAPPER.CONSENT,
      number: consentPatients.length,
      patientIdList: consentPatients,
    },
    patient: {
      id: FILTER_MAPPER.PATIENT,
      value: FILTER_MAPPER.PATIENT,
      number: sortedPatientList.length,
      patientIdList: newPatient.length ? [...newPatient, ...notNewPatient].map(p => p.id) : sortedPatientList.map(p => p.id),
    },
  };
};

const getStatValues = (filteredPatientsSurveys, isClinicalMode, typeIdsWithCountersign) => {
  if (Array.isArray(filteredPatientsSurveys)) {
    const surveysLength = filteredPatientsSurveys.length;
    const surveysLengthRatio = surveysLength > 0 ? 100 / surveysLength : 0;
    // #################
    // ##     STATS
    // #################
    let coutersigned = 0, surveysSigned = 0, inProgress = 0, furtherInfo = 0, refused = 0, withdrawal = 0;
    if (surveysLength > 0 && surveysLengthRatio > 0) {
      inProgress = filteredPatientsSurveys.filter(s => [SURVEY_STATUS.SENT, SURVEY_STATUS.IN_PROGRESS].includes(s.status)).length;
      refused = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.REFUSED).length;
      withdrawal = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.WITHDRAWAL).length;
      const acceptedSurveyList = filteredPatientsSurveys.filter(s => [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(s.status));
      surveysSigned = acceptedSurveyList.length;
      if (isClinicalMode) {
        // coutersigned surveys with type (with counter signature) and with status accepted
        coutersigned = filteredPatientsSurveys.filter(s => typeIdsWithCountersign.includes(s.type_id) && [SURVEY_STATUS.ACCEPTED].includes(s.status)).length;
        furtherInfo = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.FURTHER_INFO).length;
        surveysSigned = surveysSigned - coutersigned;
      }
    }
    const newStat = [
      { order: 1, number: coutersigned > 0 ? coutersigned * surveysLengthRatio : 0, value: SURVEY_STATUS.COUNTERSIGNED, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.COUNTERSIGNED), isVisible: isClinicalMode },
      { order: 2, number: surveysSigned > 0 ? surveysSigned * surveysLengthRatio : 0, value: SURVEY_STATUS.ACCEPTED, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.ACCEPTED), isVisible: true },
      { order: 3, number: inProgress > 0 ? inProgress * surveysLengthRatio : 0, value: SURVEY_STATUS.IN_PROGRESS, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.IN_PROGRESS), isVisible: true },
      { order: 4, number: furtherInfo > 0 ? furtherInfo * surveysLengthRatio : 0, value: SURVEY_STATUS.FURTHER_INFO, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.FURTHER_INFO), isVisible: isClinicalMode },
      { order: 5, number: refused > 0 ? refused * surveysLengthRatio : 0, value: SURVEY_STATUS.REFUSED, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.REFUSED, isClinicalMode), isVisible: true },
      { order: 6, number: withdrawal > 0 ? withdrawal * surveysLengthRatio : 0, value: SURVEY_STATUS.WITHDRAWAL, status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.WITHDRAWAL), isVisible: isClinicalMode },
    ];
    return newStat.filter(s => ('isVisible' in s) && s.isVisible === true);
  }
};
