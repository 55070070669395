
import { connect } from 'react-redux';

import { AuthActions } from '../../../redux/auth';
import { PatientActions } from '../../../redux/patient';
import { SurveyActions } from '../../../redux/survey';
import Patient from './Patient';

const mapStateToProps = ({ auth, display, patient, survey }) => {
  return {
    auth: auth,
    currentPatient: patient.currentPatient,
    currentPatientList: Array.isArray(patient.currentPatientList) ? patient.currentPatientList : [],
    indexCurrentPatient: patient.indexCurrentPatient,
    isLoadingPatient: patient.loadingPatient,
    isUserLoggedin: auth.token !== null,
    types: Object.values((survey && survey.types) || {}),
    fieldsToOmit: display.fieldsToOmit,
    surveyList: Object.values(survey.list || {}),
    isSurveyListLoading: survey.isListLoading,

  };
};

const mapDispatchToProps = (dispatch) => ({

  updateCGU: (id) => dispatch(PatientActions.patientsUpdateCguRequest(id)),
  updateRGPD: (id) => dispatch(PatientActions.patientsUpdateRGPDRequest(id)),
  surveyCurrentDelete: () => dispatch(SurveyActions.surveyCurrentDelete()),
  tutoredDetailRequest: () => dispatch(PatientActions.tutoredCurrentDetailRequest()),
  patientUpdateRequest: (id, patient, role) => dispatch(PatientActions.patientUpdateRequest(id, patient, role)),
  generateSurveyPDF: (survey) => dispatch(SurveyActions.generateSurveyPDF(survey, true, true)),
  generateSurveyDraw: (survey) => dispatch(SurveyActions.generateSurveyDraw(survey, true)),
  resumeSurveyRequest: (surveyId) => dispatch(PatientActions.resumeSurveyRequest(surveyId)),
  uploadSignRequest: (survey) => dispatch(SurveyActions.surveyUploadSign(survey)),
  surveyTypesRequest: () => dispatch(SurveyActions.surveyTypesRequest()),
  incrementIndexCurrentPatient: () => dispatch(PatientActions.incrementIndexCurrentPatient()),
  decrementIndexCurrentPatient: () => dispatch(PatientActions.decrementIndexCurrentPatient()),

  fetchPatientSurveyList: (index) => dispatch(SurveyActions.fetchPatientSurveyList(index)),
  getLoginInfo: (redirectOnSuccess) => dispatch(AuthActions.getLoginInfo(redirectOnSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
