
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { MedicTemplateService, SurveyService } from '../../services';
import i18n from '../../services/i18n/i18n';
import { defaultLanguageId } from '../../utils/const';
import { AuthActions } from '../auth';
import { FormsActions } from '../forms';
import { LoaderActions } from '../loader';
import { MedicActions } from '../medic';
import { PatientActions } from '../patient';
import { SnackActions } from '../snackBar';
import { default as SurveyActions, types } from './actions';

/**
 * Start Fetching all survey type
 * @returns {Generator<*, void, ?>}
 */
function* surveyTypesRequest() {
  const { language_id } = yield select((state) => state?.auth?.user);
  const [error, data] = yield call(SurveyService.types, [defaultLanguageId, language_id].filter(el => !!el));
  if (error || (data && !data.survey_types)) {
    yield put(SurveyActions.surveyTypesFailure());
    yield put(SnackActions.displayError('surveys_types_error'));
  } else {
    const { survey_types } = data;
    if (survey_types) {
      const surveyTypes = survey_types.map(st => {
        let userLanguage = st.survey_types_translations.find(el => el.language_id === language_id);
        if (!userLanguage) userLanguage = st.survey_types_translations.find(el => el.language_id === defaultLanguageId);
        st.translation = userLanguage;
        st.survey_types_translations = _.keyBy(st.survey_types_translations, 'language_id');
        return st;
      });
      yield put(SurveyActions.surveyTypesSuccess(surveyTypes));
    }
  }
}

/**
 * Start Fetching all survey type
 * @returns {Generator<*, void, ?>}
 */
// Add specialitiesRequest funtion to fetch the list of specialities. Could be merge with the types' call if needed
function* specialitiesRequest() {
  const { language_id } = yield select((state) => state?.auth?.user);
  const [error, data] = yield call(SurveyService.specialities, [defaultLanguageId, language_id].filter(el => !!el));
  if (!error && data && data.specialities) {
    const { specialities } = data;
    if (specialities) {
      const specialitiesList = specialities.map(st => {
        let userLanguage = st.specialities_translations.find(el => el.language_id === language_id);
        if (!userLanguage) userLanguage = st.specialities_translations.find(el => el.language_id === defaultLanguageId);
        st.translation = userLanguage;
        st.specialities_translations = _.keyBy(st.specialities_translations, 'language_id');
        return st;
      });
      yield put(SurveyActions.specialitiesSuccess(specialitiesList));
    } else {
      yield put(SurveyActions.specialitiesFailure());
      yield put(SnackActions.displayError('surveys_specialty_error'));
    }
  } else {
    yield put(SurveyActions.specialitiesFailure());
    yield put(SnackActions.displayError('surveys_specialty_error'));
  }
}

/**
 * Start Fetching all survey type
 * @returns {Generator<*, void, ?>}
 */
function* questionTypesRequest() {
  const [error, response] = yield call(SurveyService.questionTypes);
  if (error) {
    yield put(SurveyActions.questionTypesFailure());
    yield put(SnackActions.displayError('surveys_question_type_error'));
  } else {
    const question_types = response && response.question_types;
    if (question_types) {
      yield put(SurveyActions.questionTypesSuccess(question_types));
    } else {
      yield put(SurveyActions.questionTypesFailure());
      yield put(SnackActions.displayError('surveys_question_type_error'));
    }
  }
}


/**
 * surveyCurrentRequest
 * @returns void
 */
function* surveyCurrentRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.getSurvey, id);
  if (response && !error) {
    const survey = _.get(response, 'data.survey');
    yield put(SurveyActions.surveyCurrentSuccess(survey));
  }
  yield put(LoaderActions.loaded());
}

/**
 * linkSurveyPatientRequest
 * @returns void
 */
function* linkSurveyPatientRequest({ survey, attachments }) {
  yield put(LoaderActions.loading());
  // get id for survey_drawing_id
  if (attachments.draw) {
    const formDraw = new FormData();
    formDraw.append('file', attachments.draw);
    formDraw.append('file_insert_mode', 'drawing');
    const [drawError, drawResponse] = yield call(SurveyService.uploadDraw, formDraw);
    if (!drawError && drawResponse) {
      const drawings = {
        loid: drawResponse.data.oid,
        ext: 'png',
        description: '',
        title: `draw_${survey.title}`,
      };
      const [drawinullngsError, drawingsResponse] = yield call(SurveyService.createSurveyDrawings, drawings);
      if (!drawinullngsError && drawingsResponse) {
        survey = {
          ...survey,
          survey_drawing_id: drawingsResponse.insert_survey_drawings.returning[0].id,
        };
      }
    }
  }
  // get id for survey_attachment_id
  if (attachments.file) {
    const formAttachment = new FormData();
    formAttachment.append('file', attachments.file);
    formAttachment.append('file_insert_mode', 'attachment');
    const [attachmentError, attachmentSuccess] = yield call(SurveyService.uploadDraw, formAttachment);
    if (!attachmentError && attachmentSuccess) {
      const attachment = {
        loid: attachmentSuccess.data.oid,
        ext: /(?:\.([^.]+))?$/.exec(attachments.file.name)[1],
        description: '',
        title: `attachment_${survey.title}`,
      };
      const [attachmentsError, attachmentsSuccess] = yield call(SurveyService.createSurveyAttachment, attachment);
      if (!attachmentsError && attachmentsSuccess) {
        survey = {
          ...survey,
          survey_attachment_id: attachmentsSuccess.insert_survey_attachments.returning[0].id,
        };
      }
    }
  }

  // passing data by form data because questions can includes files if pdf type
  const form = new FormData();
  let hasPDFQuestions = false;
  survey.steps && Array.isArray(survey.steps) && survey.steps.forEach((step, sIndex) => {
    step.questions = Array.isArray(step.questions) && step.questions.map((question, qIndex) => {
      // default oid value set to -2 because if a new file if present, oid is set to -1
      const pdfType = _.get(question, 'data.isPDFType', false);
      const oid = _.get(question, 'data.oid', -2);
      const pdf = _.get(question, 'data.pdf', null);

      if (pdfType && oid === -1 && (pdf && pdf[0])) {
        hasPDFQuestions = true;
        form.append(`file-question-${sIndex}-${qIndex}`, question.data.pdf[0]);
        return { ...question, data: { isPDFType: true } };
      }
      return question;
    });
  });

  const payload = JSON.stringify(survey).match(/.{1,900000}/g);
  Array.isArray(payload) && payload.forEach((chunk, index) => form.append(`payload_${index}`, chunk));
  form.append('upsert_mode', 'survey');
  form.append('file_insert_mode', hasPDFQuestions ? 'pdf_template' : 'classic_template');
  const [error, response] = yield call(SurveyService.saveSurveyToPatient, form);
  if (response && !error) {
    const surveyIds = Array.isArray(response.data.survey) ? response.data.survey.map(s => s.id) : [response.data.survey.id];
    yield put(SurveyActions.linkSurveyPatientSuccess(surveyIds));
    yield put(PatientActions.patientDetailRequest(survey.patient_id, survey.medical_teams_id));
    yield put(SnackActions.displayInfo('survey_create_success'));
    yield put(FormsActions.clearData());
  } else {
    yield put(SurveyActions.linkSurveyPatientFailure());
    yield put(SnackActions.displayError('surveys_create_error'));
  }
  yield put(LoaderActions.loaded());
}

/**
 * surveyCurrentUpdateRequest
 * @returns void
 */
function* surveyCurrentUpdateRequest({ id, survey }) {
  const [error, response] = yield call(SurveyService.currentUpdateSurvey, id, survey);
  if (error) {
    yield put(SurveyActions.surveyCurrentUpdateFailure());
    yield put(SnackActions.displayError('survey_current_update_error'));
    return;
  }
  const questions = response && response.insert_questions && response.insert_questions.returning;
  if (questions) {
    yield put(SurveyActions.surveyCurrentUpdateSuccess(questions));
    return;
  }
  yield put(SurveyActions.surveyCurrentUpdateFailure());
  yield put(SnackActions.displayError('survey_current_update_error'));
}


function* rejectRequest({ id, payload }) {
  const [error, response] = yield call(SurveyService.consentReject, id, payload);

  if (!error && response) {
    yield put(SurveyActions.surveyCurrentRequest(id));
    yield put(SurveyActions.surveyCurrentDelete());
    yield put(AuthActions.setRedirectUrl('/patient'));
  } else {
    yield put(SurveyActions.surveyConsentUpdateFailure());
    yield put(SnackActions.displayError('survey_update_error'));
  }
}

/**
 * paperSurveyCreate
 * @returns void
 */
function* paperSurveyCreate({ survey }) {
  yield put(LoaderActions.loading());

  // save survey in DB
  const form = new FormData();

  form.append('file', survey.paper.file_paper);
  form.append('surveys', JSON.stringify(survey));
  form.append('file_insert_mode', 'paper_consent');
  const [error, response] = yield call(SurveyService.signPaperSurvey, form);

  if (error) {
    yield put(SurveyActions.linkSurveyPatientFailure());
    yield put(SnackActions.displayError('surveys_create_error'));
  } else {
    yield put(SnackActions.displayInfo('survey_create_success'));
    const newSurvey = response.data.survey;
    yield put(SurveyActions.surveyUploadSignSuccess(newSurvey));

    yield all(newSurvey.map((survey) => {
      return put(
        PatientActions.selectedPatientAddSurvey(survey));
    }),
    );
    const interventions = yield all(newSurvey.map((survey) => {
      return call(
        SurveyService.currentSurveyIntervention,
        survey.id,
      );
    }));
    const interventionError = interventions.reduce((acc, value) => (value[0] ? [...acc, value[0]] : acc), []);
    if (interventionError && interventionError.length) {
      yield put(SnackActions.displayInfo('upload_sign_success'));
    } else {
      const surveyToRefresh = _.keyBy(newSurvey.map((s, index) => ({ ...s, intervention: interventions[index][1] })), 'id');
      yield put(PatientActions.patientDetailSurveyRefresh(surveyToRefresh));
      yield put(SnackActions.displayInfo('upload_sign_success'));
    }
  }
  yield put(LoaderActions.loaded());
}

/**
 * temporarySurveyCreate
 * @returns void
 */
function* temporarySurveyCreate({ survey, navigate }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(MedicTemplateService.getMedicTemplateDetail, survey.templateList);
  const { data } = response;
  if (error && !data) {
    yield put(SurveyActions.linkSurveyPatientFailure());
    yield put(SnackActions.displayError('surveys_create_error'));
  } else {
    // merge the 2 surveys steps
    let medicTemplates = survey.templateList
      .map(item => data.medic_templates.find(t => parseInt(t.id, 10) === parseInt(item.id, 10)));
    const title = Array.isArray(medicTemplates) && medicTemplates.length <= 2
      ? `${medicTemplates.map(el => el.translation[0].title).join(' et ')}`
      : '';
    let number = 0;
    medicTemplates = medicTemplates.map((mt) => {
      const surveyPdfs = (mt.survey_pdfs || []).sort((a, b) => a.number > b.number).map((sp) => {
        number = number + 1;
        return {
          ...sp,
          number: number,
        };
      });
      const steps = (mt.steps || []).sort((a, b) => a.number > b.number).map((s) => {
        number = number + 1;
        return {
          ...s,
          number: number,
        };
      });
      return {
        ...mt,
        steps: steps,
        survey_pdfs: surveyPdfs,
      };
    });
    // merge the surveys steps and pdf
    medicTemplates = medicTemplates
      .reduce((acc, mt) => {
        if (_.isEmpty(acc)) {
          return {
            ..._.omit(mt, ['id', 'medic', 'created_at', 'updated_at', '__typename']),
            id: mt.id,
            translation: _.omit(mt.translation[0], ['id', 'medic_template_id', 'created_at', 'updated_at', '__typename']),
            steps: Array.isArray(mt.steps) ? mt.steps : [],
            survey_pdfs: Array.isArray(mt.survey_pdfs) ? mt.survey_pdfs : [],
          };
        } else {
          return {
            ...acc,
            steps: [
              ...acc.steps,
              ...(Array.isArray(mt.steps) ? mt.steps : []),
            ],
            survey_pdfs: [
              ...acc.survey_pdfs,
              ...(Array.isArray(mt.survey_pdfs) ? mt.survey_pdfs : []),
            ],
          };
        }
      }, {});
    medicTemplates = {
      ...medicTemplates,
      steps: (medicTemplates.steps || []).map(s => {
        return {
          ..._.omit(s, ['id', 'medic_template_id', 'created_at', 'updated_at', '__typename']),
          translation: _.omit(s.translation[0], ['id', 'step_id', 'created_at', 'updated_at', '__typename']),
          questions: (s.questions || []).map(q => ({
            ..._.omit(q, ['id', 'step_id', 'created_at', 'updated_at', '__typename']),
            translation: _.omit(q.translation[0], ['id', 'question_id', 'created_at', 'updated_at', '__typename']),
          })),
        };
      }),
      survey_pdfs: (medicTemplates.survey_pdfs || []).map(pdf => _.omit(pdf, ['id', 'created_at', 'updated_at', '__typename'])),
    };
    const temporarySurvey = {
      ...survey,
      language_id: Array.isArray(survey?.templateList[0]?.lng) ? survey.templateList[0].lng[0] : survey?.templateList[0].lng,
      title: title,
      description: medicTemplates.translation.description || [],
      template_id: medicTemplates.id,
      steps: medicTemplates.steps,
      survey_pdfs: medicTemplates.survey_pdfs,
    };
    // save the temporary Survey to redux
    yield put(SurveyActions.temporarySurveySuccess(temporarySurvey));
    navigate('/preview/consent');
  }
  yield put(LoaderActions.loaded());
}

/**
 * generatePDF
 * @returns void
 */
function* generatePDF({ surveyId, type }) {
  function* generate(id) {
    let error;
    if (type === 'surveys') {
      [error] = yield call(SurveyService.generatePDF, id);
    } else if (type === 'medic_templates') {
      [error] = yield call(MedicTemplateService.generatePDF, id);
    }

    if (error) {
      yield put(SurveyActions.surveyUploadSignFailure());
      yield put(SnackActions.displayError('upload_sign_error'));
    }
  }

  yield put(LoaderActions.loading());
  let URLs;
  if (Array.isArray(surveyId)) {
    URLs = [];
    for (let i = 0, size = surveyId.length; i < size; i++) {
      URLs.push(yield generate(surveyId[i]));
    }
  } else {
    URLs = yield generate(surveyId);
  }
  yield put(SurveyActions.generateSuccess(URLs));
  yield put(LoaderActions.loaded());
}

/**
 * generateDraw
 * @returns void
 */
function* generateDraw({ survey, toDisplay }) {
  yield put(LoaderActions.loading());
  const { survey_drawing } = survey;
  const [error, response] = yield call(
    SurveyService.generateDraw,
    survey_drawing.loid,
    survey_drawing.ext,
  );
  if (!error && response) {
    yield put(LoaderActions.loaded());
    const drawUrl = response && response.data && response.data.draw_url;
    if (toDisplay) {
      const token = yield select((state) => _.get(state, 'auth.token'));
      download_file(`${process.env.REACT_APP_SIGN_API_URL}/private/${drawUrl}`, drawUrl, token);
    }
  }
  yield put(LoaderActions.loaded());
}

/**
 * uploadSurveySign
 * @returns void
 */
function* uploadSurveySign({ survey }) {
  if (survey.file == null) {
    yield put(SurveyActions.surveyUploadSignFailure());
    yield put(SnackActions.displayError('upload_sign_error'));
    return;
  }

  yield put(LoaderActions.loading());
  const form = new FormData();
  form.append('survey_id', survey.id);
  form.append('file', survey.file);
  form.append('firstname', survey.patient.firstname);
  form.append('lastname', survey.patient.lastname);
  form.append('file_insert_mode', 'forceSign');

  const [error, response] = yield call(SurveyService.uploadSign, form);
  if (error) {
    yield put(SurveyActions.surveyUploadSignFailure());
    yield put(SnackActions.displayError('upload_sign_error'));
  } else {
    const newSurvey = response.data.survey;
    yield put(SurveyActions.surveyUploadSignSuccess(newSurvey));

    const [interventionError, interventionSuccess] = yield call(
      SurveyService.currentSurveyIntervention,
      newSurvey[0].id,
    );
    if (interventionError) {
      return;
    }
    const { interventions } = interventionSuccess;
    if (!interventions || !interventions.length) {
      return;
    }
    yield put(
      PatientActions.patientDetailSurveySuccess({
        ...newSurvey[0],
        intervention: interventions[0],
      }),
    );
    yield put(SnackActions.displayInfo('upload_sign_success'));
  }
  yield put(LoaderActions.loaded());
}


function* genPatientSignUrl({ surveyId, questions, ip, startDate, endDate }) {
  yield put(LoaderActions.loading());
  yield put(SurveyActions.genSurveySignUrlReset());
  const [error, response] = yield call(SurveyService.genPatientSignUrl, surveyId, questions, ip, startDate, endDate);
  if (error || !response) {
    // eslint-disable-next-line no-console
    console.error('genPatientSignUrl', error?.response?.data?.message);

    yield put(SnackActions.displayError(i18n.exists(error?.response?.data?.message) ? i18n.t(error?.response?.data?.message) : 'survey_sign_error_0'));
    yield put(SurveyActions.genSurveySignUrlError());
  } else {
    if (response.data.signUrl === 'already_signed') {
      yield put(SnackActions.displayWarning('already_signed'));
    }
    yield put(SurveyActions.genSurveySignUrlSuccess(response.data.signUrl));
  }
  yield put(LoaderActions.loaded());
}

function* genCounterSignUrl({ surveyId }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.contraliaCountersignUrl, surveyId);
  if (error || !response) {
    yield put(SnackActions.displayError('error'));
  } else {
    if (response.data.signUrl === 'already_signed') {
      yield put(SnackActions.displayWarning('already_signed'));
    }
    yield put(SurveyActions.genSurveySignUrlSuccess(response.data.signUrl));
  }
  yield put(LoaderActions.loaded());
}

/**
 * surveyDeleteRequest
 * @returns void
 */
function* surveyDeleteRequest({ patientId, IDs }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.archivedSome, IDs);
  if (error || !response) {
    yield put(SnackActions.displayError('surveys_delete_error'));
  } else {
    yield put(PatientActions.patientDetailSurveyDelete(patientId, IDs));
    yield put(MedicActions.medicTeamPatientSurveyDelete(patientId, IDs));
    yield put(SnackActions.displayInfo('surveys_delete_success'));
  }
  yield put(LoaderActions.loaded());
}


/**
 * surveyDeleteRequest
 * @returns void
 */
function* contraliaSignInit({ id, questions, ip, startDate, endDate }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.contraliaSignInit, id, questions, ip, startDate, endDate);
  if (!error && response) {
    yield put(SnackActions.displayInfo('sms sent'));
    yield put(AuthActions.setLastSmsSent('contralia'));
  } else {
    yield put(SnackActions.displayError('survey_update_error'));
  }
  yield put(LoaderActions.loaded());
}

/* Helper function */
function download_file(fileURL, fileName, token) {

  let anchor = document.createElement('a');
  anchor.target = '_blank';
  document.body.appendChild(anchor);

  let headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);

  fetch(fileURL, { headers })
    .then(response => response.blob())
    .then(blobby => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName || fileURL;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
}

function* contraliaNewCode({ surveyId, isPro }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(isPro ? SurveyService.contraliaCountersignNewCode : SurveyService.contraliaSignNewCodeRequest, surveyId);
  yield put(AuthActions.setLastSmsSent('contralia'));
  if (!error && response) {
    yield put(SnackActions.displayInfo('sms sent'));
  } else {
    yield put(SnackActions.displayError(error?.response?.data?.message?.code || 'an_error_occurs_getting_the_code'));
  }
  yield put(LoaderActions.loaded());
}


function* contraliaCodeCheck({ surveyId, code, isPro }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(isPro ? SurveyService.contraliaCountersignCodeCheck : SurveyService.contraliaSignCodeCheck, surveyId, code);
  if (!error && response) {
    yield put(isPro ? SurveyActions.surveyCounterSignSuccess(surveyId) : SurveyActions.surveySignSuccess(surveyId));
    yield put(SnackActions.displayInfo('survey_update_success'));
    yield call(SurveyService.updateUpdateDate, surveyId);
  } else {
    yield put(SnackActions.displayError(error?.response?.data?.message?.code || 'authentication_wrong_code'));
    yield put(isPro ? SurveyActions.surveyCounterSignFailure() : SurveyActions.surveySignFailure());
  }
  yield put(LoaderActions.loaded());
}


function* contraliaCountersignInit({ surveyId }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.contraliaCountersignInit, surveyId);
  if (!error && response) {
    yield put(AuthActions.setLastSmsSent('contralia'));
  } else {
    yield put(SnackActions.displayError(error?.response?.data?.message?.code || 'an_error_occurs_getting_the_code'));
  }
  yield put(LoaderActions.loaded());
}

function* fetchPatientSurveyList({ patientIndex }) {
  const { indexCurrentPatient, currentPatientList } = yield select((state) => state.patient);
  const patient = currentPatientList[patientIndex || indexCurrentPatient];
  const [error, data] = yield call(SurveyService.fetchPatientSurveyList, patient.id);
  if (!error && data) {
    yield put(SurveyActions.surveyListSuccess(data.surveys));
  } else {
    yield put(SnackActions.displayError('error'));
  }
}

function* withdrawSurvey({ surveyId }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(SurveyService.withdrawSurvey, surveyId);
  if (error || !response) {
    yield put(SnackActions.displayError('survey_withdrawal_error'));
  } else {
    yield put(SurveyActions.surveyWithdrawalSuccess(response.data.survey));
    yield put(SnackActions.displayInfo('survey_withdrawal_success'));
  }
  yield put(LoaderActions.loaded());
}

/**
 * MAPPING
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(types.SURVEY_TYPES_REQUEST, surveyTypesRequest),
  takeLatest(types.SPECIALITIES_REQUEST, specialitiesRequest),
  takeLatest(types.LINK_SURVEY_PATIENT_REQUEST, linkSurveyPatientRequest),
  takeLatest(types.QUESTION_TYPES_REQUEST, questionTypesRequest),
  takeLatest(types.SURVEY_CURRENT_REQUEST, surveyCurrentRequest),
  takeLatest(types.SURVEY_CURRENT_UPDATE_REQUEST, surveyCurrentUpdateRequest),
  takeLatest(types.TEMPORARY_SURVEY_CREATE, temporarySurveyCreate),
  takeLatest(types.CREATE_SURVEY_PAPER, paperSurveyCreate),
  takeLatest(types.SURVEY_UPLOAD_SIGN, uploadSurveySign),
  takeLatest(types.SURVEY_GENERATE_DRAW, generateDraw),
  takeLatest(types.SURVEY_GENERATE_PDF, generatePDF),
  takeLatest(types.SURVEY_WITHDRAWAL_REQUEST, withdrawSurvey),
  takeLatest(types.SURVEY_DELETE_REQUEST, surveyDeleteRequest),
  takeLatest(types.GEN_SURVEY_SIGN_URL, genPatientSignUrl),
  takeLatest(types.CONTRALIA_COUNTERSIGN_URL, genCounterSignUrl),


  takeLatest(types.INITIATE_CONTRALIA_SIGNATURE, contraliaSignInit),
  takeLatest(types.CONTRALIA_NEW_CODE_REQUEST, contraliaNewCode),
  takeLatest(types.CONTRALIA_CHECK_OTP, contraliaCodeCheck),
  takeLatest(types.CONTRALIA_COUNTERSIGN_INIT, contraliaCountersignInit),
  takeLatest(types.FETCH_PATIENT_SURVEY_LIST, fetchPatientSurveyList),



  takeLatest(types.REJECT_REQUEST, rejectRequest),
];
