import './style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconEdit, IconMoreOptions } from '../../../assets/img/icons';
import { AbilityContext } from '../../../services/casl/Can';
import { CONSENT_COMPONENT, MODEL_NAMES, PERMISSIONS } from '../../../services/casl/roles';
import { prepareContactForUpdate, prepareTutorForUpdate } from '../../../utils';
import { questionTypeValueToIdMapper as mapper, sortableToSurvey, surveyToSortable } from '../../../utils/dataMappers';
import { TYPE_CONST, USER_ROLE } from '../../../utils/enum';
import { getCircularReplacer } from '../../../utils/utils';
import ErrorMessage from '../../components/formV3/errors/ErrorMessage';
import Modal from '../../components/modal';
import NavigateBack from '../../components/navigateBack';
import { Button } from '../../components/styledComponent';
import ToolTip from '../../components/tooltip';
import AuthLayout from '../../layouts/authLayout';
import PdfRender from '../ConsentV2/PdfRender';
import ConsentHeader from '../createFormV2/ConsentHeader';
import Sortable from '../createFormV2/Sortable';
import Step from '../createFormV2/Step';
import Commentary from './Commentary';
import ConsentType from './ConsentType';
import { yupPreviewSchema } from './FormModel/validationSchema';


const ConsentPreview = ({
  surveyTypesRequest,
  questionTypesRequest,
  specialitiesRequest,
  patient,
  patientDetailRequest,
  linkSurveyPatientRequest,
  deleteTemporarySurvey,
  deleteLocalLink,
  questionTypes,
  link,
  patientUpdateRequest,
  currentMedic,
  selectedTeam,
  selectedPatient,
  clearFormData,
  reduxForms,
  initialValues,
  token,
  onTeamChange,
  questionTypeIdsCheckboxes,
  medicalTeamList,
  resumeSurveyRequest,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (questionTypesRequest) questionTypesRequest();
    if (surveyTypesRequest) surveyTypesRequest();
    if (specialitiesRequest) specialitiesRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues && initialValues.patient_id && (!!selectedPatient || selectedPatient.id !== initialValues.patient_id)) {
      patientDetailRequest && patientDetailRequest(initialValues.patient_id);
    }
  }, [initialValues && initialValues.patient_id]);

  useEffect(() => {
    if (initialValues.medical_teams_id) {
      onTeamChange && onTeamChange(initialValues.medical_teams_id);
    }
  }, [initialValues && initialValues.medical_teams_id]);


  const [isReOrderModalOpen, setIsReOrderModalOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [finish, setFinish] = useState(false);
  const [tutorStep, setTutorStep] = useState(1);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [prevDefaultValues, setPrevDefaultValues] = useState();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(yupPreviewSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const { handleSubmit, watch, setValue, reset } = formMethods;

  const canEdit = ability.can(PERMISSIONS.UPDATE, MODEL_NAMES.CONSENT);

  const isClinicalMode = useMemo(() => {
    return _.get(medicalTeamList, [initialValues?.medical_teams_id, 'role', 'is_clinical_study'], false);
  }, [initialValues, medicalTeamList]);

  useEffect(() => {
    // if more than 2 template are merge for this survey => clear title so medic can fill it
    if (initialValues && initialValues.template_id && Array.isArray(initialValues.template_id) && initialValues.template_id.length > 2)
      setValue('title', '');
  }, [initialValues && initialValues.template_id]);

  useEffect(() => {
    if (_.isObject(initialValues) && !_.isEqual(initialValues, prevDefaultValues)){
      reset({ ...initialValues });
      setPrevDefaultValues({ ...initialValues });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const { fields: stepFields, remove } = useFieldArray({
    control: formMethods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });
  const { fields: pdfFields } = useFieldArray({
    control: formMethods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'survey_pdfs', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });

  const stepsWatch = useWatch({
    control: formMethods.control,
    name: 'steps',
  });

  useEffect(() => {
    if (link && link.length) {
      setFinish(true);
      if (clearFormData) clearFormData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link && link.length]);

  useEffect(() => {
    _.isEmpty(patient) && onNavigationBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const handleSendEmailToPatient = (surveyId) => {
    if (!hasSentEmail) {
      resumeSurveyRequest && resumeSurveyRequest(surveyId);
      setHasSentEmail(true);
    }
  };

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error('onErrors', errors);
  };

  const onNavigationBack = () => {
    navigate('/');
  };

  const handleEditMode = () => {
    if (!editable) {
      setEditable(true);
    }
  };

  const handleReOrderModalOpen = () => {
    setIsReOrderModalOpen(true);
  };

  const handleSortableClose = () => {
    setIsReOrderModalOpen(!isReOrderModalOpen);
  };

  const preparedSteps = steps => (steps || []).map(s => ({
    number: s.number,
    translation: {
      language_id: s.translation.language_id,
      title: s.translation.title,
    },
    questions: (s.questions || []).map((q) => {
      const { answer, ...questionData } = q.translation?.data;
      if (questionTypeIdsCheckboxes.includes(q.type_id)) {
        let list = questionData.list;
        questionData.list = Array.isArray(list) ? list.map((l) => l.value) : list;
      }
      return {
        number: q.number,
        translation: {
          language_id: q.translation.language_id,
          question: q.translation.question,
          data: questionData,
          desc: q.translation?.desc,
          rewording: q.translation?.rewording,
          texte_pdf: q.translation?.texte_pdf,
          incompris: q.translation?.incompris,
        },
        type_id: q.type_id || mapper(questionTypes, q.type),
      };
    }),
  }));

  const onSubmits = (data) => {
    if (editable) {
      setEditable(false);
      return;
    }

    const survey = {
      ..._.omit(initialValues, ['title', 'desc', 'type', 'medic_surveys', 'referent_medic_id', 'template_id', 'store_id', 'old_id', 'order', 'survey_pdfs',
        'speciality', 'referent_medic_label']),
      description: Array.isArray(data.description) ? data.description : null,
      title: data.title,
      template_id: initialValues.template_id,
      clinic_id: initialValues.clinic_id,
      patient_id: initialValues.patient_id,
      tutored_id: null,
      comment: data.comment,
      type_id: data.type_id || initialValues.type_id,
      speciality_id: data.speciality_id || initialValues.speciality_id,
      side: JSON.stringify(data.side),
      radius: JSON.stringify(data.radius),
      creator_id: currentMedic.id,
      referent_medic_id: initialValues.referent_medic_id,
      medical_teams_id: initialValues.medical_teams_id || selectedTeam,
      steps: preparedSteps(data.steps),
      survey_pdfs: (data.survey_pdfs || []).map(el => _.omit(el, ['medic_template_id'])),
      medic_surveys: initialValues.medic_surveys,
    };

    const attachments = {
      file : data.attachment && data.attachment[0],
      draw: data.paintAttachment,
    };

    linkSurveyPatientRequest(survey, attachments);
  };

  const handleModalClose = () => {
    setFinish(false);
  };

  const handleFinish = (type) => {
    const tutors = selectedPatient.tutorsByPatientId && Array.isArray(selectedPatient.tutorsByPatientId)
      ? selectedPatient.tutorsByPatientId.map(el => el.signatory)
      : Array.isArray(selectedPatient.signatories) ? [...selectedPatient.signatories] : [];
    const tutorsQty = Array.isArray(tutors) && tutors.length;
    if (editable) {
      // if edit mode then quit edit mode
      setEditable(false);
    } else {
      if (tutorsQty && tutorsQty > tutorStep) {
        setTutorStep(tutorStep + 1);
      } else {
        if (deleteTemporarySurvey) deleteTemporarySurvey();
        if (deleteLocalLink) deleteLocalLink();
        onNavigationBack();
      }
    }
  };

  const handleReorderComplete = (newData) => {
    const newSteps = sortableToSurvey(newData, stepsWatch);
    setValue('steps', newSteps);
    setIsReOrderModalOpen(false);
  };


  const handlePatientUpdate = (toUpdate, type, patientId, tutorId) => {
    const ROLE = USER_ROLE.MEDIC;
    switch (type) {
      case TYPE_CONST.PATIENT:
        const formatedData = _.omitBy(prepareContactForUpdate(toUpdate), _.isNil);
        if (patientUpdateRequest) patientUpdateRequest(patientId, formatedData, ROLE);
        break;
      case TYPE_CONST.TUTOR:
        const formatedTutorData = _.omitBy(prepareTutorForUpdate(toUpdate), _.isNil);
        if (patientUpdateRequest)
          patientUpdateRequest(tutorId, {
            ...formatedTutorData,
            tutored_id: patientId,
          }, ROLE);
        break;
      default:
      // default
    }
    handleFinish();
  };

  let counter = 0;


  return (
    <AuthLayout>
      <ToolTip />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className="consent-preview-screen">
          <ConsentHeader
            createMode
            readOnly={editable === false}
            disabled={editable === false}
            consentPreview
            initialValues={initialValues}
          />
          { ability.can(PERMISSIONS.UPDATE, { __typename: MODEL_NAMES.CONSENT_COMPONENT, value: CONSENT_COMPONENT.COMMENTARY }) && (
            <Commentary />
          )}
          <NavigateBack text={t('change_form')} onClick={onNavigationBack} />
          <div className="position-absolute d-flex consent-preview-header-buttons-container">
            { !isClinicalMode && (ability.can(PERMISSIONS.UPDATE, MODEL_NAMES.CONSENT) && canEdit) && (
              <>
                <Button
                  variant="icon"
                  onClick={!editable ? handleEditMode : handleReOrderModalOpen}
                  data-tip={!editable ? t('edit') : t('reorder_translation')}
                  type="button"
                  minWidth={0}
                  disabled={isClinicalMode}
                >
                  <img
                    src={!editable ? IconEdit : IconMoreOptions}
                    alt={!editable ? t('edit') : t('more_options')}
                  />
                </Button>
              </>
            ) }
            <Button type="submit">{t('validate')}</Button>
          </div>
          <div className={`d-flex flex-column ${editable ? '' : 'consent-preview-step-masker'}`}>
            { pdfFields.map((pdf) => (
              <div key={pdf.key} className={`order-${pdf.number}`} >
                <PdfRender
                  pdf={pdf}
                  readOnly={true}
                  hideBtn
                />
              </div>
            )) }
            { stepFields.map((step, index) => (
              <div key={step.key } className={`order-${step.number}`}>
                <Step
                  step={step}
                  createMode
                  readOnly={editable === false}
                  disabled={editable === false}
                  control={formMethods.control}
                  prefixName={`steps.${index}`}
                  onDeleteStep={() => remove(index)}
                  // currentSurvey={{ __typename: 'medic_templates', id: step.medic_template_id }}
                  consentPreview
                />
              </div>
            )) }
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <>
            <div style={{ display: 'block', width: '100%', justifyContent: 'center' }}>
              <div style={{ flexDirection: 'column', marginRight: 0, marginLeft: 'auto' }}>
                <div style={{ margin: 'auto' }}>
                  Render <span>{ counter += 1 }</span>
                </div>
                <div style={{ margin: 'auto' }}>
                  <ErrorMessage />
                </div>
                <div style={{ margin: 'auto' }}>
                  <pre>{JSON.stringify(watch(), getCircularReplacer(), 2)}</pre>
                </div>
              </div>
            </div>
          </>
        )}
      </FormProvider>
      { isReOrderModalOpen && (
        <Sortable list={surveyToSortable(stepsWatch)} onExit={handleSortableClose} onFinish={handleReorderComplete} />
      )}
      <div className={'consent-preview-modal'}>
        <Modal show onClose={handleModalClose} hideCloseBtn isOpen={finish && tutorStep > 0}>
          <ConsentType
            surveyId={link[tutorStep - 1]}
            initialValues={initialValues}
            reduxForms={reduxForms}
            onFinish={handleFinish}
            onPatientUpdate={handlePatientUpdate}
            token={token}
            tutor={_.get(selectedPatient, ['signatories', tutorStep - 1])}
            currentPatient={selectedPatient}
            signatory={_.get(selectedPatient, ['signatories', tutorStep - 1]) || selectedPatient}
            onEmailRequest={surveyId => handleSendEmailToPatient(surveyId)}
          />
        </Modal>
      </div>
    </AuthLayout>
  );
};

ConsentPreview.propTypes = {
  surveyTypesRequest: PropTypes.func,
  questionTypesRequest: PropTypes.func,
  specialitiesRequest: PropTypes.func,
  patient: PropTypes.object,
  patientDetailRequest: PropTypes.func,
  linkSurveyPatientRequest: PropTypes.func,
  deleteTemporarySurvey: PropTypes.func,
  deleteLocalLink: PropTypes.func,
  current: PropTypes.object,
  types: PropTypes.array,
  questionTypes: PropTypes.array,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  surveyUpsertWithPDFRequest: PropTypes.func,
  resetGeneratedURL: PropTypes.func,
  emailAvailable: PropTypes.object,
  emailLoading: PropTypes.bool,
  patientUpdateRequest: PropTypes.func,
  currentMedic: PropTypes.object,
  selectedTeam: PropTypes.number,
  selectedPatient: PropTypes.object,
  clearFormData: PropTypes.func,
  reduxForms: PropTypes.object,
  initialValues: PropTypes.object,
};

export default ConsentPreview;
