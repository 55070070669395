import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCER: 'patient_RESET_REDUCER',

  START_LOADER: 'patient_START_LOADER',
  STOP_LOADER: 'patient_STOP_LOADER',

  PATIENTS_REQUEST: 'patient_PATIENTS_REQUEST',
  PATIENTS_SUCCESS: 'patient_PATIENTS_SUCCESS',
  PATIENTS_FAILURE: 'patient_PATIENTS_FAILURE',

  PATIENTS_PATCH_REQUEST: 'patient_PATIENTS_PATCH_REQUEST',
  PATIENTS_PATCH_FAILURE: 'patient_PATIENTS_PATCH_FAILURE',

  PATIENTS_DETAIL_REQUEST: 'patient_PATIENTS_DETAIL_REQUEST',
  PATIENTS_DETAIL_SUCCESS: 'patient_PATIENTS_DETAIL_SUCCESS',
  PATIENTS_DETAIL_FAILURE: 'patient_PATIENTS_DETAIL_FAILURE',
  PATIENTS_DETAIL_SURVEY_SUCCESS: 'patient_PATIENTS_DETAIL_SURVEY_SUCCESS',

  PATIENTS_CURRENT_DETAIL_SUCCESS: 'patient_PATIENT_CURRENT_DETAIL_SUCCESS',
  PATIENTS_CURRENT_DETAIL_FAILURE: 'patient_PATIENT_CURRENT_DETAIL_FAILURE',

  TUTORED_CURRENT_DETAIL_REQUEST: 'patient_TUTORED_CURRENT_DETAIL_REQUEST',
  TUTORED_CURRENT_DETAIL_SUCCESS: 'patient_TUTORED_CURRENT_DETAIL_SUCCESS',
  TUTORED_CURRENT_DETAIL_FAILURE: 'patient_TUTORED_CURRENT_DETAIL_FAILURE',

  PATIENTS_CREATE_REQUEST: 'patient_PATIENTS_CREATE_REQUEST',
  PATIENTS_CREATE_SUCCESS: 'patient_PATIENTS_CREATE_SUCCESS',
  PATIENTS_CREATE_FAILURE: 'patient_PATIENTS_CREATE_FAILURE',

  PATIENTS_UPDATE_REQUEST: 'patient_PATIENTS_UPDATE_REQUEST',
  PATIENTS_UPDATE_SUCCESS: 'patient_PATIENTS_UPDATE_SUCCESS',
  PATIENTS_UPDATE_FAILURE: 'patient_PATIENTS_UPDATE_FAILURE',

  PATIENTS_MY_UPDATE_REQUEST: 'patient_PATIENTS_MY_UPDATE_REQUEST',
  PATIENTS_MY_UPDATE_SUCCESS: 'patient_PATIENTS_MY_UPDATE_SUCCESS',
  PATIENTS_MY_UPDATE_FAILURE: 'patient_PATIENTS_MY_UPDATE_FAILURE',

  DELETE_CURRENT_PATIENT: 'patient_DELETE_CURRENT_PATIENT',

  PATIENT_UPDATE_PASSWORD_SUCCESS: 'patient_PATIENT_UPDATE_PASSWORD_SUCCESS',

  PATIENTS_UPDATE_CGU_REQUEST: 'patient_PATIENTS_UPDATE_CGU_REQUEST',
  PATIENTS_UPDATE_CGU_SUCCESS: 'patient_PATIENTS_UPDATE_CGU_SUCCESS',
  PATIENTS_UPDATE_CGU_FAILURE: 'patient_PATIENTS_UPDATE_CGU_FAILURE',

  PATIENTS_UPDATE_RGPD_REQUEST: 'patient_PATIENTS_UPDATE_RGPD_REQUEST',
  PATIENTS_UPDATE_RGPD_SUCCESS: 'patient_PATIENTS_UPDATE_RGPD_SUCCESS',
  PATIENTS_UPDATE_RGPD_FAILURE: 'patient_PATIENTS_UPDATE_RGPD_FAILURE',

  PATIENTS_LEGAL_RESET_SUCCESS: 'patient_PATIENTS_LEGAL_RESET_SUCCESS',

  PATIENTS_EMAIL_REQUEST: 'patient_PATIENTS_EMAIL_REQUEST',
  PATIENTS_EMAIL_SUCCESS: 'patient_PATIENTS_EMAIL_SUCCESS',
  PATIENTS_EMAIL_FAILURE: 'patient_PATIENTS_EMAIL_FAILURE',

  PATIENTS_UPDATE_EDIT_INFO_REQUEST: 'patient_PATIENTS_UPDATE_EDIT_INFO_REQUEST',
  PATIENTS_UPDATE_EDIT_INFO_SUCCESS: 'patient_PATIENTS_UPDATE_EDIT_INFO_SUCCESS',
  PATIENTS_UPDATE_EDIT_INFO_FAILURE: 'patient_PATIENTS_UPDATE_EDIT_INFO_FAILURE',

  PATIENTS_SURVEY_RESUME_REQUEST: 'patient_PATIENTS_SURVEY_RESUME_REQUEST',
  PATIENTS_SURVEY_RESUME_REQUEST_SUCCESS: 'patient_PATIENTS_SURVEY_RESUME_REQUEST_SUCCESS',
  PATIENTS_SURVEY_RESUME_REQUEST_FAILURE: 'patient_PATIENTS_SURVEY_RESUME_REQUEST_FAILURE',
  PATIENTS_INTERVENTION_UPDATE_REQUEST: 'patient_INTERVENTION_UPDATE_REQUEST',
  PATIENTS_INTERVENTION_UPDATE_SUCCESS: 'patient_INTERVENTION_UPDATE_SUCCESS',

  PATIENTS_WITHDRAWAL_SUCCESS: 'patient_PATIENTS_WITHDRAWAL_SUCCESS',


  PATIENTS_GENERATE_SIGNATURE_CODE: 'patient_PATIENTS_SEND_SIGNATURE_CODE',
  PATIENTS_GENERATE_SIGNATURE_CODE_SUCCESS: 'patient_PATIENTS_SEND_SIGNATURE_CODE_SUCCESS',
  PATIENTS_GENERATE_SIGNATURE_CODE_FAILURE: 'patient_PATIENTS_SEND_SIGNATURE_CODE_FAILURE',

  PATIENTS_MESSENGER_SURVEY_CREATED: 'patient_PATIENTS_MESSENGER_SURVEY_CREATED',

  PATIENTS_SEND_SMS_TO_REQUEST: 'patient_PATIENTS_SEND_SMS_TO_REQUEST',

  PATIENTS_SEND_MAIL_TO_REQUEST: 'patient_PATIENTS_SEND_MAIL_TO_REQUEST',

  PATIENTS_DETAIL_SURVEY_REFRESH: 'patient_PATIENTS_DETAIL_SURVEY_REFRESH',
  PATIENTS_DETAIL_SURVEY_STATUS_REFRESH: 'PATIENTS_DETAIL_SURVEY_STATUS_REFRESH',
  PATIENTS_DETAIL_SURVEY_DELETE: 'patient_PATIENTS_DETAIL_SURVEY_DELETE',

  RESET_UPLOAD_SUCCESS: 'patient_RESET_UPLOAD_SUCCESS',

  PATIENTS_PER_MEDICTEAM_SUCCESS: 'patient_PATIENTS_PER_MEDICTEAM_SUCCESS',

  INCREMENT_INDEX_CURRENT_PATIENT: 'patient_INCREMENT_INDEX_CURRENT_PATIENT',
  DECREMENT_INDEX_CURRENT_PATIENT: 'patient_DECREMENT_INDEX_CURRENT_PATIENT',

  PATIENT_DETAIL_REQUEST_FOR_CONSENT: 'patient_PATIENT_DETAIL_REQUEST_FOR_CONSENT',

  PATIENT_UPDATE_PROPERTY_SUCCESS: 'patient_PATIENT_UPDATE_PROPERTY_SUCCESS',

  PATIENT_CLEAR_SELECTED_PATIENT: 'patient_PATIENT_CLEAR_SELECTED_PATIENT',

  SELECTED_PATIENT_ADD_SURVEY: 'patient_SLECTED_PATIENT_ADD_SURVEY',

  PATIENTS_TO_ARCHIVE: 'patient_PATIENTS_TO_ARCHIVE',

};

// Actions
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  startLoader: () => createAction(types.START_LOADER),
  stopLoader: () => createAction(types.STOP_LOADER),
  /** REQUEST */
  patientsRequest: (ids = null) => createAction(types.PATIENTS_REQUEST, { ids }),
  patientsSuccess: (patients, shouldMerge = false) => createAction(types.PATIENTS_SUCCESS, { patients, shouldMerge }),
  patientsFailure: () => createAction(types.PATIENTS_FAILURE),

  /* PATCH */
  patientsPatchRequest: (patientId, data) => createAction(types.PATIENTS_PATCH_REQUEST, { patientId, data }),
  patientsPatchFailure: () => createAction(types.PATIENTS_PATCH_FAILURE),

  /* DETAIL */
  patientDetailRequest: (patientId, selectedTeam = null, params = { loading: true }) => createAction(types.PATIENTS_DETAIL_REQUEST, { patientId, selectedTeam, params }),
  patientDetailSuccess: (patient) => createAction(types.PATIENTS_DETAIL_SUCCESS, { patient }),
  patientDetailFailure: () => createAction(types.PATIENTS_DETAIL_FAILURE),
  patientDetailSurveySuccess: (survey) => createAction(types.PATIENTS_DETAIL_SURVEY_SUCCESS, { survey }),

  /* MY DETAIL*/
  patientCurrentDetailSuccess: (patientList, patientIndex = null) => createAction(types.PATIENTS_CURRENT_DETAIL_SUCCESS, { patientList, patientIndex }),
  patientCurrentDetailFailure: () => createAction(types.PATIENTS_CURRENT_DETAIL_FAILURE),

  /* TUTORED */
  tutoredCurrentDetailRequest: () => createAction(types.TUTORED_CURRENT_DETAIL_REQUEST),
  tutoredCurrentDetailSuccess: (tutored) => createAction(types.TUTORED_CURRENT_DETAIL_SUCCESS, { tutored }),
  tutoredCurrentDetailFailure: () => createAction(types.TUTORED_CURRENT_DETAIL_FAILURE),

  /* CREATE */
  patientsCreateRequest: (patients) => createAction(types.PATIENTS_CREATE_REQUEST, { patients }),
  patientsCreateSuccess: (newPatientList) => createAction(types.PATIENTS_CREATE_SUCCESS, { newPatientList }),
  patientsCreateFailure: () => createAction(types.PATIENTS_CREATE_FAILURE),

  /* UPDATE */
  patientUpdateRequest: (id, patient) => createAction(types.PATIENTS_UPDATE_REQUEST, { id, patient }),
  patientUpdateSuccess: (patient) => createAction(types.PATIENTS_UPDATE_SUCCESS, { patient }),
  patientUpdateFailure: () => createAction(types.PATIENTS_UPDATE_FAILURE),
  patientArchiveRequest: (patientId, selectedTeam) => createAction(types.PATIENTS_TO_ARCHIVE, { patientId, selectedTeam }),


  /* MY_UPDATE */
  patientMyUpdateRequest: (patient) => createAction(types.PATIENTS_MY_UPDATE_REQUEST, { patient }),
  patientMyUpdateFailure: () => createAction(types.PATIENTS_MY_UPDATE_FAILURE),

  checkIfEmailExistsInDb: (email, userId = null) => createAction(types.PATIENTS_EMAIL_REQUEST, { email, userId }),
  startEmailLoader: () => createAction(types.PATIENTS_EMAIL_REQUEST),
  patientsEmailSuccess: (message) => createAction(types.PATIENTS_EMAIL_SUCCESS, { message }),
  patientsEmailFailure: () => createAction(types.PATIENTS_EMAIL_FAILURE),

  deleteCurrentPatient: () => createAction(types.DELETE_CURRENT_PATIENT),

  resumeSurveyRequest: (surveyId) => createAction(types.PATIENTS_SURVEY_RESUME_REQUEST, { surveyId }),
  resumeSurveySuccess: (surveyId) => createAction(types.PATIENTS_SURVEY_RESUME_REQUEST_SUCCESS, { surveyId }),
  resumeSurveyFailure: () => createAction(types.PATIENTS_SURVEY_RESUME_REQUEST_FAILURE),
  interventionUpdateRequest: (interventionNumber, date, dateWarning = null) => createAction(types.PATIENTS_INTERVENTION_UPDATE_REQUEST, { interventionNumber, date, dateWarning }),
  interventionUpdateSuccess: (newPatient) => createAction(types.PATIENTS_INTERVENTION_UPDATE_SUCCESS, { newPatient }),

  patientUpdatePasswordSuccess: () => createAction(types.PATIENT_UPDATE_PASSWORD_SUCCESS),

  /* CGU */
  patientsUpdateCguRequest: (id) => createAction(types.PATIENTS_UPDATE_CGU_REQUEST, { id }),
  patientsUpdateCguSuccess: (accepted_cgu) => createAction(types.PATIENTS_UPDATE_CGU_SUCCESS, { accepted_cgu }),
  patientsUpdateCguFailure: () => createAction(types.PATIENTS_UPDATE_CGU_FAILURE),

  /* RGPD */
  patientsUpdateRGPDRequest: (id) => createAction(types.PATIENTS_UPDATE_RGPD_REQUEST, { id }),
  patientsUpdateRGPDSuccess: (accepted_rgpd) => createAction(types.PATIENTS_UPDATE_RGPD_SUCCESS, { accepted_rgpd }),
  patientsUpdateRGPDFailure: () => createAction(types.PATIENTS_UPDATE_RGPD_FAILURE),

  /* EDIT INFO */
  patientsUpdateEditInfoRequest: (id) => createAction(types.PATIENTS_UPDATE_EDIT_INFO_REQUEST, { id }),
  patientsUpdateEditInfoSuccess: (edit_info) => createAction(types.PATIENTS_UPDATE_EDIT_INFO_SUCCESS, { edit_info }),
  patientsUpdateEditInfoFailure: () => createAction(types.PATIENTS_UPDATE_EDIT_INFO_FAILURE),

  patientResetLegalSuccess: () => createAction(types.PATIENTS_LEGAL_RESET_SUCCESS),


  /* Signature */
  generateSignatureCode: (surveyId) => createAction(types.PATIENTS_GENERATE_SIGNATURE_CODE, { surveyId }),
  generateSignatureCodeSuccess: () => createAction(types.PATIENTS_GENERATE_SIGNATURE_CODE_SUCCESS),
  generateSignatureCodeFailure: () => createAction(types.PATIENTS_GENERATE_SIGNATURE_CODE_FAILURE),

  patientDetailSurveyRefresh: (survey) => createAction(types.PATIENTS_DETAIL_SURVEY_REFRESH, { survey }),
  patientDetailSurveyStatusRefresh: (id, status) => createAction(types.PATIENTS_DETAIL_SURVEY_STATUS_REFRESH, { id, status }),
  patientDetailSurveyDelete: (patientId, IDs) => createAction(types.PATIENTS_DETAIL_SURVEY_DELETE, { patientId, IDs }),

  resetUploadSuccess: () => createAction(types.RESET_UPLOAD_SUCCESS),

  resetReducer: () => createAction(types.RESET_REDUCER),

  incrementIndexCurrentPatient: () => createAction(types.INCREMENT_INDEX_CURRENT_PATIENT),
  decrementIndexCurrentPatient: () => createAction(types.DECREMENT_INDEX_CURRENT_PATIENT),

  patientDetailRequestForConsent: (surveyId) => createAction(types.PATIENT_DETAIL_REQUEST_FOR_CONSENT, { surveyId }),

  patientUpdatePropertySuccess: (patientId, update) => createAction(types.PATIENT_UPDATE_PROPERTY_SUCCESS, { patientId, update }),

  clearSelectedPatient: () => createAction(types.PATIENT_CLEAR_SELECTED_PATIENT),
  selectedPatientAddSurvey: (survey) => createAction(types.SELECTED_PATIENT_ADD_SURVEY, { survey }),
};
