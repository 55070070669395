import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCounterSign } from '../../../../assets/img/icons';

function ShowActionCounterSign({ element, index, onClick, canCountersign, referent }) {
  const { t } = useTranslation();
  if (element.survey_type && element.survey_type.should_countersign) {
    return (
      <button className="action-wrapper p-0 link" onClick={() => canCountersign && onClick(element, index, canCountersign)}>
        <img
          src={IconCounterSign}
          data-tip={canCountersign ? t('medicPatient.history.list.actionBtn.countersign') : t('ShowActionCounterSign.toBeCountersignBy', { medic: `${referent?.firstname || ''} ${referent?.lastname || ''}` })}
          className={canCountersign ? '' : 'image-disabled'}
          alt={canCountersign ? 'image' : 'image-disabled'}
          style={{ width: '36px', height: '36px' }}
        />
      </button>
    );
  } else {
    return <div />;
  }
};

ShowActionCounterSign.propTypes = { referent: PropTypes.object, element: PropTypes.object, status: PropTypes.string, onClick: PropTypes.func, index: PropTypes.number };

export default ShowActionCounterSign;
