import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCalendar, IconDeleteForm, IconQrCode, IconRenewForm, IconUploadFormLg } from '../../../../assets/img/icons';
import { SURVEY_STATUS } from '../../../../utils';
import { CONTENT_TYPE_MAPPER } from '../../../../utils/enum';
import ConfirmBtn from '../../ConfirmBtn';
import FileUploader from '../../fileUploadButton';
import { Button } from '../../styledComponent';
import DocumentChoice, { ACTIONS } from './DocumentChoice';
import IconEditRender from './IconEditRender';
import IconSeeRender from './IconSeeRender';
import ItemChoice from './ItemChoice';
import ShowActionCounterSign from './ShowActionCounterSign';

function ActionRender({
  survey,
  index,
  isClinicalMode,
  onClick,
  onItemClick,
  modal,
  setModal,
  patient,
  bearerToken,
  action,
  onClearAction,
  isSurveyPast,
  onClickEdit,
  onClickVoir,
  onClickCounterSign,
  onPreviewPDF,
  medic,
  canCountersign,
}) {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const status = survey.status;
  const isActiveBtn = {
    resume: (isClinicalMode ? [
      SURVEY_STATUS.SENT,
      SURVEY_STATUS.FURTHER_INFO,
      SURVEY_STATUS.REFUSED,
    ] : [
      SURVEY_STATUS.SENT,
      SURVEY_STATUS.REFUSED,
    ]).includes(status),
    upload: !modal?.survey?.is_signed && !isSurveyPast,
    qrCode: !modal?.survey?.is_signed && !isSurveyPast,
  };


  const handleConfirmClick = (_e, a) => {
    switch (a) {
      case 'delete':
        onClick('delete', null);
        setShowConfirm(false);
        break;
      case 'modal':
      default:
        setShowConfirm(false);
        break;
    }
    setShowConfirm(false);
  };

  return (
    <div className="d-flex flex-wrap flex-xl-nowrap">
      <Button
        onClick={() => isActiveBtn.resume && onClick('resume', index)}
        disabled={!isActiveBtn.resume}
        minWidth='36px'
        width='36px'
        height='36px'
        padding='4px'
        className="m-0"
      >
        <img
          src={IconRenewForm}
          data-tip={isActiveBtn.resume ? t('medicPatient.history.list.actionBtn.resume') : ''}
          className={isActiveBtn.resume ? '' : 'image-disabled'}
          alt={isActiveBtn.resume ? 'image' : 'image-disabled'}
        />
      </Button>
      {(modal.show && modal.type === 'parametersChoice') && (
        <DocumentChoice
          onModalClose={() => setModal({ show: false })}
          survey={modal.survey}
          patient={patient}
          action={action}
          onClearAction={onClearAction}
          bearerToken={bearerToken}
        >
          <FileUploader
            buttonClassName={'infopatient-button-action'}
            buttonTitle={t('patient_button_upload')}
            handleFile={(file) => isActiveBtn.upload && onPreviewPDF(file, survey)}
            uploadIcon={IconUploadFormLg}
            iconClassname={`imgUploadLvl2 ${isActiveBtn.upload ? '' : 'image-disabled'}`}
            disabled={!isActiveBtn.upload}
            tooltip={t('patient_button_upload')}
            accept={`${CONTENT_TYPE_MAPPER.PDF}`}
            label={'Sélectionner un fichier'}
          />
          <ItemChoice
            active
            onClick={() => onItemClick(ACTIONS.EDIT_DATE)}
            imgSrc={IconCalendar}
            title={t('edit_date')}
          />
          <ItemChoice
            active={isActiveBtn.qrCode}
            onClick={() => isActiveBtn.qrCode && onItemClick(ACTIONS.GENERATE_QRCODE)}
            imgSrc={IconQrCode}
            title={t('generate_qr_code')}
          />
        </DocumentChoice>
      )}
      <IconEditRender
        element={survey}
        index={index}
        onClick={onClickEdit}
      />
      <IconSeeRender
        element={survey}
        index={index}
        status={status}
        onClick={onClickVoir}
      />
      <ShowActionCounterSign
        canCountersign={canCountersign}
        element={survey}
        status={status}
        index={index}
        referent={survey?.referent}
        onClick={onClickCounterSign}
      />
      <ConfirmBtn
        title={t('confirm_delete_template')}
        onConfirm={handleConfirmClick}
        action={ACTIONS.DELETE}
        isOpen={showConfirm}
      >
        <img
          className={'form-list-delete'}
          src={IconDeleteForm}
          data-tip={t('medicPatient.history.list.actionBtn.delete')}
          alt="patient_button_delete"
        />
      </ConfirmBtn>
    </div>
  );
}

ActionRender.propTypes = {
  survey: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isClinicalMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  patient:  PropTypes.object.isRequired,
  bearerToken: PropTypes.string.isRequired,
  action: PropTypes.string,
  onClearAction: PropTypes.func.isRequired,
  isSurveyPast: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickVoir: PropTypes.func.isRequired,
  onClickCounterSign: PropTypes.func.isRequired,
  onPreviewPDF: PropTypes.func.isRequired,
  medic: PropTypes.object.isRequired,
};

export default ActionRender;
