import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SURVEY_STATUS } from '../../../../utils';
import { getCircularReplacer } from '../../../../utils/utils';
import Card from '../../../components/card';
import ErrorMessage from '../../../components/formV3/errors/ErrorMessage';
import ConsentHeader from '../../createFormV2/ConsentHeader';
import IntroQuestionsModal from '../IntroQuestionsModal';
import PdfRender from '../PdfRender';
import StepRender from '../StepRender';
import yupSchema from '../yupSchema';

function ConsentRender({ survey, updateData, readOnly, onRejectSurvey, questionTypes }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [isOpenIntroModal, setIsOpenIntroModal] = useState(true);
  const [hasValidateIntro, setHasValidateIntro] = useState({ before: false, after:false });
  const [showSummary, setShowSummary]= useState(false);
  const [initialSuvrey, setInitialSuvrey] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [lastStepId, setLastStepId] = useState(0);
  const [maxStepNumber, setMaxStepNumber] = useState(0);
  const isClinicalMode = useMemo(() => survey?.survey_type?.is_clinical_study || false, [survey?.survey_type?.is_clinical_study]);
  const startDate = useMemo(() => new Date(), []);

  useEffect(() => {
    const stepList = [...(survey?.steps || []), ...(survey?.survey_pdfs || [])].sort((a, b) => a.number > b.number);
    const lastStep = Array.isArray(stepList) && stepList.length ? stepList[stepList.length -1 ] : 0;
    setLastStepId((lastStep && lastStep.id) || 0);
    setMaxStepNumber((lastStep && lastStep.number) || 0);
  }, [survey && (survey.steps || []).map(el => el.id), survey && (survey.survey_pdfs || []).map(el => el.id)]);

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return survey;
    }, [survey]),
    resolver: !readOnly && questionTypes && yupResolver(yupSchema(questionTypes)),
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const { handleSubmit, watch, control, reset, trigger } = formMethods;
  const { fields: stepFields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'steps', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });
  const { fields: pdfFields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'survey_pdfs', // unique name for your Field Array
    keyName: 'key', // When your array field contains objects with the key name id, useFieldArray will overwrite and remove it. If you want to keep the id field in your array of objects, you must use keyName prop to change to other name.
  });

  useEffect(() => {
    setIsOpenIntroModal(!readOnly);
  }, [readOnly]);

  useEffect(() => {
    if (!readOnly && data && hasValidateIntro.before && hasValidateIntro.after) {
      navigate(`/consent/${survey.id}/recap`);
    }
  }, [data, hasValidateIntro]);

  useEffect(() => {
    setCurrentStep(1);
  }, [survey]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (!_.isEqual(initialSuvrey, survey)) {
      setInitialSuvrey({ ...survey });
      reset({ ...survey });
    }
  }, [survey]);

  const onSubmits = (formData) => {
    if (readOnly) {
      navigate('/');
    } else {
      const result = {
        startDate,
        id: survey.id,
        survey_pdfs: formData.survey_pdfs,
        steps: (formData.steps || []).map((step) => {
          return {
            ...step,
            questions: (step.questions || []).map(q => ({
              id: q.id,
              step_id: step.id,
              translation: {
                question: q.translation.question,
                desc: q.translation.desc,
                texte_pdf: q.translation.texte_pdf,
                data: q.translation.data,
              },
              asked_more_info: q.asked_more_info || false,
              answer: q.answer,
              type_id: q.type_id,
            })),
          };
        }, []),
      };
      setData(result);
      updateData('consent', result);
      setShowSummary(true);
      setIsOpenIntroModal(true);
    }
  };

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error('errors', errors);
  };

  let counter = 0;

  const handleNextStep = async () => {
    const pdfIndex = (survey?.survey_pdfs || []).findIndex(p => p.number === currentStep);
    const stepIndex = (survey?.steps || []).findIndex(p => p.number === currentStep);
    const isValid = await trigger(`${pdfIndex >= 0 ? 'survey_pdfs' : 'steps'}.${pdfIndex >= 0 ? pdfIndex : stepIndex}`, { shouldFocus: true });
    if (isValid) {
      if (currentStep >= maxStepNumber) {
        handleSubmit(onSubmits, onErrors);
        return;
      }
      setCurrentStep(step => (step + 1 > maxStepNumber ? maxStepNumber : step + 1));
    } else {
      handleSubmit(onSubmits, onErrors);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(step => (step - 1 > 0 ? step - 1 : 0));
  };

  const handleRejectSurvey = (errorMessage, status = SURVEY_STATUS.REFUSED) => {
    onRejectSurvey(survey.id, {
      is_signed: true,
      status: status,
      error_message: errorMessage,
    });
  };

  const handleRejectSurveyOnIntroQ = () => {
    handleRejectSurvey( t('ConsentRender.rejection_message.intro'), isClinicalMode ? SURVEY_STATUS.FURTHER_INFO : SURVEY_STATUS.REFUSED);
  };

  const handleRejectStep = (text, status) => {
    handleRejectSurvey( text || t('ConsentRender.rejection_message.intro'), status || SURVEY_STATUS.REFUSED);
  };

  const handleIntroValidate = () => {
    setHasValidateIntro( { ...hasValidateIntro, [showSummary ? 'after' : 'before'] : true });
    setIsOpenIntroModal(false);
  };

  return (
    <>
      {!readOnly && (
        <IntroQuestionsModal
          survey={survey}
          onCancel={handleRejectSurveyOnIntroQ}
          isAfter={showSummary}
          isOpen={isOpenIntroModal}
          onLastClick={handleIntroValidate}
        />
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmits, onErrors)} className="consent-screen-container">
          <ConsentHeader
            survey={survey}
            disabled={true}
            disabledType
            isConsent
            isPatientReading
          />

          { survey && survey.comment && (
            <Card>
              <div
                id="consent-preview-commentary-area-consent"
                className="consent-commentary-area" >
                {survey && survey.comment}
              </div>
            </Card>
          )}

          <div className="d-flex flex-column">
            { stepFields.map((step, index) => (
              <div
                key={step.key}
                className={`order-${step.number} ${currentStep === step.number ? '' : 'd-none'}`}
              >
                <StepRender
                  isConsent
                  editReasonOnRefusal={survey?.survey_type?.edit_reason_on_refusal || false}
                  step={step}
                  index={index}
                  steps={survey.steps || []}
                  numberSteps={100 / survey.steps.length}
                  stepNumber={index}
                  onPreviousStep={handlePreviousStep}
                  onNext={handleNextStep}
                  isLast={step.id === lastStepId}
                  readOnly={readOnly}
                  onRejectStep={handleRejectStep}
                  isClinicalMode={isClinicalMode}
                />
              </div>
            )) }
            { pdfFields.map((pdf) => (
              <div
                key={pdf.key}
                className={`order-${pdf.number} ${currentStep === pdf.number ? '' : 'd-none'}`}
              >
                <PdfRender
                  pdf={pdf}
                  onValidatePDF={handleNextStep}
                  isLast={pdf.id === lastStepId}
                  readOnly={readOnly}
                />
              </div>
            )) }
          </div>
        </form>
        {!!process.env.REACT_APP_DEBUG_FORM && (
          <div className="container-fluid w-50">
            <div style={{ flexDirection: 'column', marginRight: 0, marginLeft: 'auto' }}>
              <div style={{ margin: 'auto' }}>
              Render : <span>{counter += 1} time</span>
              </div>
              <div style={{ margin: 'auto' }}>
                <ErrorMessage />
              </div>
              <div style={{ margin: 'auto' }} >
                <pre>{JSON.stringify(watch(), getCircularReplacer(), 2)}</pre>
              </div>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
}

ConsentRender.propTypes = {};

export default ConsentRender;
