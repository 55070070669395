import './style.css';

import { endOfDay, isPast } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { parseDate } from '../../../services/datefns';
import { SURVEY_STATUS } from '../../../utils/enum';
import AuthLayout from '../../layouts/authLayout';
import ConsentRender from './ConsentRender';

const Consent = ({
  surveyCurrentRequest,
  surveyCurrentDelete,
  ipRequest,
  questionTypesRequest,
  resetStatus,
  resetIsSigned,
  currentSurvey,
  introQuestionRequest,
  resetStep,
  updateData,
  onRejectSurvey,
  questionTypes,
  setIsClinicalStudy,
}) => {
  const navigate = useNavigate();
  const { consentId } = useParams();
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    // set display.isClinicalStudy to true if the consent is survey_type should_countersign
    setIsClinicalStudy(currentSurvey?.survey_type?.should_countersign);
  }, [currentSurvey?.survey_type?.should_countersign]);

  useEffect(() => {
    // cancel if status is not sent (readonly = false), signed or countersigned (readonly = true)
    setReadOnly(currentSurvey?.status !== SURVEY_STATUS.SENT);
    if (currentSurvey?.status && ![SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.SENT].includes(currentSurvey.status) ) {
      onCancel();
    }
  }, [currentSurvey?.status]);

  useEffect(() => {
    if (questionTypesRequest) questionTypesRequest();
    if (resetStatus) resetStatus();
    if (resetStep) resetStep();
    if (ipRequest) ipRequest();
  }, []);

  useEffect(() => {
    if (!consentId) {
      navigate('/');
    } else {
      surveyCurrentRequest && surveyCurrentRequest(consentId);
    }
  }, [consentId]);

  useEffect(() => {
    if (currentSurvey?.type_id) {
      introQuestionRequest && introQuestionRequest(`${currentSurvey.type_id}`, currentSurvey.language_id || 1);
    }
  }, [currentSurvey && currentSurvey.type_id]);

  useEffect(() => {
    if (!readOnly && currentSurvey?.intervention?.date) {
      if (isPast(endOfDay(parseDate(currentSurvey.intervention.date)))) {
        // cancel if intervention date is passed
        onCancel();
      }
    }
  }, [readOnly, currentSurvey?.intervention?.date]);

  const onCancel = () => {
    if (navigate) {
      if (surveyCurrentDelete) {
        surveyCurrentDelete();
      }
      if (resetStatus) {
        resetStatus();
      }
      if (resetIsSigned) {
        resetIsSigned();
      }
      navigate('/');
    }
  };

  return (
    <AuthLayout>
      <ConsentRender
        survey={currentSurvey}
        navigate={navigate}
        updateData={updateData}
        readOnly={readOnly}
        onRejectSurvey={onRejectSurvey}
        questionTypes={questionTypes}
      />
    </AuthLayout>
  );
};

export default Consent;
