
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { scrollToTop } from '../../../../utils/utils';
import Item from './Item';
import ItemCardTemplate from './ItemTemplate';

const InfiniteLoadList = ({ chunk, onClick, selectedTeam, isClinicalMode, medicId, selectedFilter }) => {
  const { t } = useTranslation();
  const loader = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  const handleObserver = useCallback((entries) => {
    if (hasMore){
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current && chunk.length) observer.observe(loader.current);
    return () => {
      if (loader.current) observer.disconnect();
    };
  }, [handleObserver, JSON.stringify(chunk)]);

  useEffect(() => {
    if (Array.isArray(chunk)) {
      loadFunc(1);
      scrollToTop();
      setHasMore(true);
    } else {
      setList([]);
    }

    return function cleanUp() {
      setList([]);
    };
  }, [JSON.stringify(chunk)]);

  useEffect(() => {
    if (page) {
      loadFunc(page);
    }
  }, [page]);

  const loadFunc = (newPage) => {
    if (newPage !== page){
      setPage(newPage);
    }
    const index = newPage - 1 ;
    const hasMore = index < chunk.length;
    setList( chunk.slice(0, newPage).reduce((acc, val) => [...acc, ...val], []) );
    setHasMore(hasMore);
  };

  return (
    <>
      { Array.isArray(list) && list.length
        ? (
          list.map((patient, index) => (
            <Item
              key={`patient-list-item-${patient.id}`}
              patient={patient}
              onClick={onClick}
              selectedTeam={selectedTeam}
              isClinicalMode={isClinicalMode}
              medicId={medicId}
              index={index}
            />
          ))
        ) : (
          <ItemCardTemplate
            fullName={t(`patient_card_empty_list${selectedFilter ? `_${selectedFilter}` : ''}`)}
          />
        )}
      <div ref={loader} />
    </>
  );
};

InfiniteLoadList.propTypes = {
  chunk: PropTypes.array,
  onClick: PropTypes.func,
  selectedTeam: PropTypes.number,
  isClinicalMode: PropTypes.bool,
  medicId: PropTypes.number,
};

export default InfiniteLoadList;
