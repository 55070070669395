import './style.css';

import { decode } from 'he';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getUserProperty } from '../../../../utils';
import { HTML_TAGS } from '../../../../utils/const';
import { escapeMetaCharacter } from '../../../../utils/utils';
import Card from '../../../components/card';
import { Button } from '../../../components/styledComponent';
import PdfRender from '../PdfRender';
import SummaryModalQuestions from './SummaryModalQuestions';

export const reformatString = (str) => {
  if (!str) return str;
  return decode(
    str
      .replace(new RegExp(`</?(${HTML_TAGS.map((el) => escapeMetaCharacter(el)).join('|')})( ([^>]*))?>`, 'g'), '')
      .replace(/<img[^>]*>/g, '')
      .replace(/<iframe[^>]*>.*<\/iframe>/g, '')
      .replace(/<br( \/|\/|)>/g, ' ')
      .replace(/ {2,10}}/g, ' ') // remove multiple space
      .trim(),
  );
};

const SummaryModal = ({
  survey,
  consent,
  onPrevious,
  readOnly,
  questionTypes,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  const handleCloseSurvey = () => {
    navigate('/');
  };

  return (
    <div className="consent-recap-summary-modal-container">
      <Card>
        <p className="consent-recap-titles "> {t('preview_answers')} </p>
        <p className="consent-recap-grey-texts">
          {t('SummaryModal.disclamer_header', { phoneNumber: survey && (survey.tutored?.local_phone_number || survey.patient?.local_phone_number) }) }
        </p>
        <p className=""> {survey && getUserProperty(survey.tutored || survey.patient, 'contactNameWithTitle') + ','} </p>
        <p className=" consent-recap-black-texts-margin">
          {t('SummaryModal.you_said')}
        </p>
        { ((consent?.steps) || []).map((step, sIndex) => {
          const counter = consent.steps.slice(0, sIndex).reduce((acc, s) => acc + s.questions.length || 0, 0);
          return (
            <div className={`order-${step.number} `} key={`step_${step.id}`}>
              { (step.questions || []).map((q, qIndex) => (
                <div
                  key={`question-${q.id}`}
                  className="d-flex flex-sm-row flex-column"
                >
                  <div className="consent-recap-titles-container p-2">
                    <p className="nunitosans-bold text-nowrap mt-0"> {`${t('answer')} ${counter + qIndex + 1}:`} </p>
                  </div>
                  <div className={'consent-recap-container p-2'} >
                    <SummaryModalQuestions
                      questionTypes={questionTypes}
                      question={q}
                    />
                  </div>
                </div>
              )) }
            </div>
          );})}
        { ((consent?.survey_pdfs) || []).map((pdf) => (
          <div className={`order-${pdf.number} `} key={`pdf_${pdf.id}`} >
            <PdfRender pdf={pdf} hideBtn />
          </div>
        )) }
        <div className="order-100 mx-4 my-2 ">
          {readOnly
            ? (
              <div className="d-flex flex-column flex-md-row align-self-stretch align-items-stretch">
                <Button
                  onClick={handlePrevious}
                  variant={'ghost'}
                >
                  {t('consentFormStep.action.previous.btn')}
                </Button>
                <Button onClick={handleCloseSurvey} >
                  {t('consentFormStep.action.return_patient.btn')}
                </Button>
              </div>
            ) : (
              null
            )}
        </div>
      </Card>
    </div>
  );
};

export default SummaryModal;