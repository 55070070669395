import './style.css';

import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';

import {
  IconCheckbox,
  IconCheckboxFull,
  IconDropDownList,
  IconPDF,
  IconTextAnswerSmall,
  IconValidation,
} from '../../../../assets/img/icons';
import { QUESTION_TYPES } from '../../../../utils/enum';

const typeToTooltip = {
  [QUESTION_TYPES.validation]: 'Validation',
  [QUESTION_TYPES.text_answer]: 'Réponse <br /> texte',
  [QUESTION_TYPES.multiple_choice]: 'Choix <br /> multiple',
  [QUESTION_TYPES.dropdown_list]: 'Liste <br /> déroulante',
  [QUESTION_TYPES.pdf]: 'PDF',
  [QUESTION_TYPES.checkbox_full]: 'Case <br /> à cocher',
};

const Question = ({ type, text, onClick, disabled }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const onDragStart = (event) => {
    // UTILISER react-dnd POUR MEILLEUR EXPÉRIENCE
    event.dataTransfer.setData('text/plain', JSON.stringify(type));
  };

  const ImageForType = () => {
    const { value } = type;
    switch (value) {
      case QUESTION_TYPES.validation:
        return IconValidation;
      case QUESTION_TYPES.text_answer:
        return IconTextAnswerSmall;
      case QUESTION_TYPES.multiple_choice:
        return IconCheckbox;
      case QUESTION_TYPES.dropdown_list:
        return IconDropDownList;
      case QUESTION_TYPES.pdf:
        return IconPDF;
      case QUESTION_TYPES.checkbox_full:
        return IconCheckboxFull;
      default:
        return IconValidation;
    }
  };

  return (
    <div
      className={`createform-menu-single-question-container ${disabled ? 'disabled' : ''}`}
      draggable
      onDragStart={(e) => !disabled && onDragStart(e)}
      onClick={isMobile ? !disabled && onClick : undefined}
      data-tip={typeToTooltip[type]}
      data-for='hideHeightOver800'
    >
      <div className="createform-menu-single-question-image-container">
        <img src={ImageForType()} alt="createform-menu-single-question" />
      </div>
      <p className="createform-menu-single-question-text">{text}</p>
    </div>
  );
};

export default Question;
