// MEDIC SIDEMENU ICONS
export { default as IconSearch } from './icon-search@3x.png';

// STAT ICONS
export { default as IconReformulate } from './illu-reformulation.svg';
export { default as IconRefused } from './illu-refus.svg';
export { default as IconFurtherInfo } from './illu-further-info.svg';
export { default as IconSigned } from './illu-signature.svg';
export { default as IconCountersigned } from './illu-countersignature.svg';

// BUTTON ICONS
export { default as IconAdd } from './icon-ajouter.svg';
export { default as IconAddPatientWhite } from './icon-ajouter-un-patient-white.svg';
export { default as IconForm } from './icon-formulaire.svg';
export { default as IconMenuForm } from './icon-menu-formulaire.svg';
export { default as IconConsent } from './icon-filtre-consentement.svg';
export { default as IconIntervention } from './icon-intervention.svg';
export { default as IconPatient } from './icon-patients-patientes.svg';
export { default as IconBack } from './icon-back.svg';
export { default as IconEdit } from './icon-editer3x.svg';
export { default as IconDraw } from './icon-dessin@3x.png';
export { default as IconAttachment } from './icon-pj@3x.png';
export { default as IconPlus } from './icon-ajout-etape@3x.png';
export { default as IconTextAnswer } from './icon-reponse-texte@3x.png';
export { default as IconTextAnswerSmall } from './icon-reponse-texte.png';
export { default as IconDropdown } from './icon-dropdown@3x.png';
export { default as IconMultipleChoiceSmall } from './icon-choix-multiple.png';

// FORM ICONS
export { default as IconAddPatient } from './icon-ajouter-un-patient.svg';
export { default as IconBuy } from './icon-acheter.svg';
export { default as IconValidation } from './icon-validation-button-radio.svg';
export { default as IconDropDownList } from './icon-liste-deroulante.svg';
export { default as IconDelete } from './icon-supprimer.svg';
export { default as IconMoreOptions } from './icon-more-option.svg';
export { default as IconAskForDetails } from './icon-ecrire-remarque.svg';
export { default as IconReadOnly } from './icon-readOnly.svg';
export { default as IconFindMore } from './icon-find.svg';
export { default as IconPDF } from './icon_pdf.svg';
export { default as IconCheckbox } from './checkbox.svg';
export { default as IconCheckboxFull } from './checkbox-full.svg';
export { default as IconInfoDetails } from './icon-info-details.svg';

// FILTERS ICONS
export { default as IconAnesthesia } from './icon-anesthesie.svg';
export { default as IconSurgery } from './icon-chirurgie.svg';
export { default as IconExams } from './icon-examens.svg';
export { default as IconInterventionalRadiology } from './icon-radiologie-interventionnelle.svg';
export { default as IconAll } from './icon-tous.svg';

// HEADER ICON
export { default as IconRafter } from './icon-chevron.svg';
export { default as IconProfil } from './icon-patient.svg';
export { default as IconLogout } from './icon-logout.png';

// fontawesome icons
export { ReactComponent as IconStore } from './fontawesome-store.svg';
export { ReactComponent as IconUserMd } from './fontawesome-user-md.svg';

// FORM LIST ACTION ICON
export { default as IconRenewForm } from './icon-renew.svg';
export { default as IconUploadForm } from './icon-upload.svg';
export { default as IconUploadFormLg } from './icon-upload-lg.svg';
export { default as IconSeeForm } from './icon-eye.svg';
export { default as IconEditForm } from './icon-edit.svg';
export { default as IconDeleteForm } from './icon-delete.svg';
export { default as IconContactForm } from './icon-contact.svg';
export { default as IconCounterSign } from './icon-countersign.svg';
export { default as IconConsentPdf } from './icon-consent.svg';
export { default as IconQrCode } from './icon-qrcode.svg';
export { default as IconCalendar } from './icon-calendar.svg';
export { default as IconInfo } from './icon-info.svg';
export { default as IconWithdrawalStat } from './withdrawal-consent-stat.svg';
export { default as IconWithdrawalBtn } from './withdrawal-consent-btn.svg';
