import '../style.css';

import { endOfDay, format, isAfter, isDate, parseISO } from 'date-fns';
import _ from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IconAttachment,
  IconConsentPdf,
  IconDraw,
  IconInfo,
} from '../../../../../assets/img/icons';
import { getUserProperty, SURVEY_STATUS, USER_ROLE } from '../../../../../utils';
import { COUNTERSIGN_INVALID_NUMBER } from '../../../../../utils/const';
import { extractParams, insertParam } from '../../../../../utils/router-helper';
import { isValidPhoneNumber } from '../../../../../utils/utils.js';
import { ModalButtons } from '../../../buttons';
import Modal from '../../../modal';
import PDFViewer from '../../../PDFViewer';
import ToolTip from '../../../tooltip';
import ActionRender from '../ActionRender';
import DocumentChoice, { ACTIONS } from '../DocumentChoice';
import IconSeeRender from '../IconSeeRender';
import ItemChoice from '../ItemChoice';
import MedicCounterSignModal from '../MedicCounterSignModal';
import Status from '../Status';
import WithdrawalConsentBtn from '../WithdrawalConsentBtn /';

const INITIAL_STATE = {
  file: null,
  consent: null,
};

const Item = ({
  logginUserRole,
  bearerToken,
  surveyList,
  medic,
  patient,
  intervention,
  onClick,
  onResetIsSigned,
  tutors,
  languageCode,
  isPatient,
  isSigned,
  pdfURL,
  preselectedID,
  resetAutoLoginSurveyID,
  isClinicalMode,
  params,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [uploadObject, setUploadObect] = useState(INITIAL_STATE);
  const [modal, setModal] = useState({ show: false });
  const [counterSignSurvey, setCounterSignSurvey] = useState(null);
  const [isCounterSignOpen, setIsCounterSignOpen] = useState(false);
  const [surveysIDs] = useState(surveyList.map(s => s.id));
  const { number } = intervention;
  const [action, setAction] = useState();
  const [isOpenInvalidNumber, setIsOpenInvalidNumber] = useState(false);
  const [countersignInfos, setCountersignInfos] = useState({});
  const [urlParams, setUrlParams] = useState();
  const interventionDate = intervention?.date ? isDate(intervention?.date) ? intervention?.date : parseISO(intervention?.date) : null;

  const isSurveyPast = useMemo(() => {
    if (interventionDate){
      if (isAfter(endOfDay(new Date()), endOfDay(interventionDate))){
        // cancel if intervention date is passed
        return true;
      }
    }
    return false;
  }, [interventionDate]);

  useEffect(() => {
    setUrlParams(extractParams(searchParams));
  }, [searchParams]);

  useEffect(() => {
    if (urlParams && urlParams.csids && urlParams.index && !isPatient) {
      if (parseInt(urlParams.csids)=== surveyList[0].id) {
        setIsOpenInvalidNumber(false);
        handleClickCounterSign(surveyList[0], urlParams.index);
      }
    }
  }, [medic?.phone_number]);

  useEffect(() => {
    if (((typeof isSigned === 'boolean') || (isSigned instanceof Boolean)) && isSigned) {
      if (onResetIsSigned) {
        onResetIsSigned();
      }
      setUploadObect(INITIAL_STATE);
    }
  }, [isSigned]);

  useEffect(() => {
    if (pdfURL && pdfURL.length && (counterSignSurvey !== null || surveysIDs.includes(preselectedID))) {
      if (counterSignSurvey === null) {
        setCounterSignSurvey(surveyList.filter(s => s.id === preselectedID)[0]);
        if (resetAutoLoginSurveyID) resetAutoLoginSurveyID();
      }
      handleClick(ACTIONS.RESET_PDF_URL);
    }
  }, [pdfURL]);

  const handleClick = (action, i, toDisplay = true, saveUser = false) => {
    if (onClick) {
      onClick({
        action: action,
        survey: action === ACTIONS.DELETE ? surveyList : surveyList[i],
        toDisplay,
        saveUser: saveUser,
      });
    }
  };

  const handleClickItem = (action) => {
    setAction(action);
  };

  const onClearAction = () => {
    setAction();
  };

  const handleClickSign = (survey) => {
    if (isPatient && survey && !survey.is_signed && !isSurveyPast) {
      navigate(`/consent/${survey.id}`);
    }
  };

  const handleClickVoir = (survey, index) => {
    if ( survey && (survey.is_signed || survey.survey_drawing_id || survey.survey_attachment_id) ) {
      setModal({
        show: true,
        type: 'documentChoice',
        survey: {
          ...survey,
          tabIndex: index,
        },
      });
    }
  };

  const handleClickEdit = (survey, index) => {
    setModal({
      show: true,
      type: 'parametersChoice',
      survey: {
        ...survey,
        tabIndex: index,
      },
    });
  };

  const handleClickCounterSign = async (currentSurvey, index, canMedicCountersign) => {
    // On check si le medic a la permission de contresigner si on arrive d'une redirection, sinon on récupère la permission déja calculée si on arrive du parcours normal.
    canMedicCountersign = urlParams.csids ? canCountersign(currentSurvey) : canMedicCountersign;
    if (!canMedicCountersign && currentSurvey.is_signed) {
      handleClick(ACTIONS.COUNTERSIGNED_INFO_MESSAGE, -1, false, false);
    } else if (canMedicCountersign) {
      setCounterSignSurvey(currentSurvey);
      if (!medic.phone_number || !isValidPhoneNumber(medic.phone_number)) {
        setIsCounterSignOpen(false);
        setIsOpenInvalidNumber(true);
        setCountersignInfos({
          currentSurvey: currentSurvey,
          index: index,
        });
      } else {
        setIsCounterSignOpen(true);
        handleClick(ACTIONS.BUILD_PDF, index, false, true);
      }
    }

  };

  const handleMedicCounterSignClose = () => {
    setIsCounterSignOpen(false);
    setCounterSignSurvey(null);
  };

  const handlerFileUpload = () => {
    onClick({
      action: ACTIONS.UPLOAD_SIGN,
      survey: {
        ...uploadObject.consent,
        file: uploadObject.file,
      },
    });
  };

  const onFileRefuse = () => {
    setUploadObect(INITIAL_STATE);
  };


  const handleCounterSignSuccess = () => {
    if (preselectedID) {
      handleClick(ACTIONS.DELETE_TOKEN, null);
    }
    handleMedicCounterSignClose();
  };


  const onPreviewPDF = async (file, consent) => {
    setModal({ show: false });
    setUploadObect({ file, consent });
  };

  const canCountersign = (survey) => {
    const isReferentSurvey = (survey.referent_medic_id && medic && survey.referent_medic_id === medic.id) || false;
    const canCountersignSurvey
      = survey.status === SURVEY_STATUS.ACCEPTED
      && survey.survey_type && survey.survey_type.should_countersign
      && isReferentSurvey;
    if (canCountersignSurvey) return true;
    return false;
  };


  const handleCounterSignInvalidNumber = () => {
    const urlParamsCountersign = new URLSearchParams();
    const paramsCountersign = insertParam(urlParamsCountersign, [
      { key: 'query', value: COUNTERSIGN_INVALID_NUMBER },
      { key: 'csidp', value: patient.id },
      { key: 'csids', value:countersignInfos.currentSurvey.id },
      { key: 'index', value:countersignInfos.index },
    ]);
    const redirectCountersignInvalidNumber = `/profil?${paramsCountersign.toString()}`;
    navigate(redirectCountersignInvalidNumber);
  };

  const handleCloseCounterSignInvalidNumber = () => {
    setIsOpenInvalidNumber(false);
  };

  const { file } = uploadObject;

  return (
    <Fragment>
      <ToolTip />
      {(modal.show && modal.type === 'documentChoice') && (
        <DocumentChoice
          onModalClose={() => setModal({ show: false })}
          survey={modal.survey}
          token={bearerToken}
          action={action}
          onClearAction={onClearAction}
        >
          <ItemChoice
            active={[SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.WITHDRAWAL].includes(modal.survey['status']) || false}
            onClick={() => handleClickItem(ACTIONS.BUILD_PDF)}
            imgSrc={IconConsentPdf}
            title={t('consent')}
          />
          <ItemChoice
            active={modal.survey['survey_attachment_id'] > 0}
            onClick={() => handleClickItem(ACTIONS.BUILD_ATTACHMENT)}
            imgSrc={IconAttachment}
            title={t('attachment')}
          />
          <ItemChoice
            active={modal.survey['survey_drawing_id'] > 0}
            onClick={() => handleClickItem(ACTIONS.BUILD_DRAW)}
            imgSrc={IconDraw}
            title={t('draw')}
          />
          {(isPatient && !isClinicalMode) && (
            <ItemChoice
              active={((modal.survey.status === SURVEY_STATUS.ACCEPTED || modal.survey.status === SURVEY_STATUS.COUNTERSIGNED) && modal.survey.survey_steps_aggregate.aggregate.count > 0)}
              onClick={() => handleClickItem(ACTIONS.BUILD_INFO)}
              imgSrc={IconInfo}
              title={t('review_consent')}
            />
          )}
        </DocumentChoice>
      )}
      <Modal title={t('valid_document')} hideCloseBtn onClose={onFileRefuse} isOpen={!!file} >
        <PDFViewer documentData={uploadObject} />
        <ModalButtons
          firstText={t('cancel')}
          onFirstClick={onFileRefuse}
          secondText={t('validate')}
          onSecondClick={handlerFileUpload}
        />
      </Modal>
      { isCounterSignOpen && (
        <MedicCounterSignModal
          onClose={handleMedicCounterSignClose}
          survey={counterSignSurvey}
          onSignSuccess={handleCounterSignSuccess}
        />
      )
      }
      {isOpenInvalidNumber
      && <Modal title={t('invalid_number')} hideCloseBtn isOpen={isOpenInvalidNumber}>
        <p>{t('countersign_invalid_number')}</p>
        <ModalButtons
          firstText={t('form.button.cancel.label')}
          onFirstClick={handleCloseCounterSignInvalidNumber}
          secondText={t('edit_phone_number')}
          onSecondClick={() => handleCounterSignInvalidNumber(counterSignSurvey)}
        />
      </Modal>
      }

      <div
        className={`row
          justify-content-between
          align-items-center
          px-md-2
          infopatient-historique-item
          ${tutors && tutors.length ? 'infopatient-historique-item-tutor' : 'infopatient-historique-item'}
          ${isPatient ? ' is-patient' : ''}`
        }
      >
        <div className="d-block d-md-none col ">
          <p>
            {number ? `N° ${number}` : ''} | {interventionDate ? format(interventionDate, 'dd/MM/yyyy') : ''}
          </p>
          <p data-tip={surveyList[0].title}>
            {(surveyList && surveyList[0] && surveyList[0].title) || ''}
          </p>
        </div>
        <div className='d-none d-md-block col survey-name' data-tip={surveyList[0].title}>
          <p className='survey-name survey-name-bold'>
            {(surveyList && surveyList[0] && surveyList[0].title) || ''}
          </p>
          <p>{(surveyList && surveyList[0] && surveyList[0]?.referent && `${surveyList[0].referent?.firstname} ${surveyList[0].referent?.lastname}`) || ''}</p>
        </div>

        <p className="d-none d-md-block col ">
          {number ? `N°${number}` : ''}
        </p>
        <p className="d-none d-md-block col ">
          {interventionDate ? format(interventionDate, 'dd/MM/yyyy') : ''}
        </p>
        {tutors && tutors.length > 0 && (
          <div className={'col '}>
            { surveyList.map((s, i) => {
              const tutor = tutors && tutors.find((t) => _.get(t, 'signatory.id') === s.tutored_id);
              return (
                <div key={`tutor-${i}`} className="patient-details-tutors-name">
                  { tutor && tutor.signatory
                    ? getUserProperty(tutor.signatory, 'fullContactName')
                    : ''
                  }
                </div>
              );
            })}
          </div>
        )}
        <div className="col ">
          {surveyList.map((element, i) => (
            <Status
              key={i}
              survey={element}
              language={languageCode}
              userRole={logginUserRole || USER_ROLE.PATIENT}
              isClinicalMode={isClinicalMode}
              onClickSign={handleClickSign}
              disabled={isSurveyPast}
            />
          ))}
        </div>
        <div className={'col d-flex flex-row '}>
          <div className={'d-flex flex-column'}>
            {surveyList.map((s, i) => {
              const tutor = tutors && tutors.find((t) => _.get(t, 'signatory.id') === s.tutored_id);
              return (
                <div key={i}>
                  { isPatient
                    ? (
                      <div className="action-wrapper d-flex flex-wrap flex-md-nowrap">
                        <IconSeeRender
                          element={s}
                          status={s.status}
                          index={i}
                          onClick={handleClickVoir}
                        />
                        <WithdrawalConsentBtn
                          survey={s}
                          status={s.status}
                        />
                      </div>
                    ) : (
                      <ActionRender
                        canCountersign={canCountersign(s)}
                        survey={s}
                        index={i}
                        tutor={tutor && tutor.signatory}
                        isClinicalMode={isClinicalMode}
                        onClick={handleClick}
                        onItemClick={handleClickItem}
                        modal={modal}
                        setModal={setModal}
                        patient={patient}
                        bearerToken={bearerToken}
                        action={action}
                        onClearAction={onClearAction}
                        isSurveyPast={isSurveyPast}
                        onClickEdit={handleClickEdit}
                        onClickVoir={handleClickVoir}
                        onClickCounterSign={handleClickCounterSign}
                        onPreviewPDF={onPreviewPDF}
                        medic={medic}
                      />
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Item;
