
export const APP_MODES = { CLINICAL_STUDY: 'clinicalStudies', HOSPI: 'hospi', COMMON: 'common' };

export const FRONT_AREA = {
  PRO: 'pro',
  PATIENT: 'patient',
};

export const SURVEY_STATUS = {
  SENT: 'sent', // When survey is created
  ACCEPTED: 'accepted', // When survey is signed by the patient
  REFUSED: 'refused',
  COUNTERSIGNED: 'countersigned',
  DRAFT: 'draft',
  FURTHER_INFO: 'further information',
  IN_PROGRESS: 'in_progress',
  NOT_SIGNED: 'not_signed',
  WITHDRAWAL: 'withdrawal',
};

export const TOKEN_ACTIONS = {
  forgotPassword: 'forgot_pwd',
  signConsent: 'sign_consent',
  counterSignConsent: 'countersign_consent',
  attachUserAccount: 'attachUserAccount',
};

export const SURVEY_STATUS_COLOUR = (status, isClinicalMode = false) => {
  let colour = 'grey';
  switch (status) {
    case SURVEY_STATUS.COUNTERSIGNED:
      colour = 'green';
      break;
    case SURVEY_STATUS.ACCEPTED:
      colour = 'blue';
      break;
    case SURVEY_STATUS.IN_PROGRESS:
    case SURVEY_STATUS.SENT:
      colour = 'yellow';
      break;
    case SURVEY_STATUS.FURTHER_INFO:
      colour = 'red';
      break;
    case SURVEY_STATUS.NOT_SIGNED:
    case SURVEY_STATUS.REFUSED:
      colour = isClinicalMode ? 'black' : 'red';
      break;
    case SURVEY_STATUS.WITHDRAWAL:
      colour = 'bluegrey';
      break;
    case SURVEY_STATUS.DRAFT:
    default:
      colour = 'grey';
  }
  return colour;
};

export const USER_ROLE = {
  MEDIC: 'medic',
  PATIENT: 'patient',
  CONNECT: 'calimed',
  TESTER: 'tester',
};

export const CLINICAL_STUDY_ROLE = {
  ADMIN_CLINICAL_STUDY: 'admin_clinical',
  INVESTIGATOR: 'investigator',
  EASY_CONSENT: 'easyconsent',
};

export const MEDIC_ROLES = [USER_ROLE.CONNECT, USER_ROLE.MEDIC, USER_ROLE.TESTER];

export const TEMPLATE_TYPE = {
  MEDIC: 'medic',
  STORE: 'store',
};

export const TYPE_CONST = {
  CONTACT: 'contact',
  INFO: 'info',
  TUTOR: 'tutor',
  PATIENT: 'patient',
};

export const QUESTION_TYPES = {
  validation: 'validation',
  text_answer: 'text_answer',
  dropdown_list: 'dropdown_list',
  multiple_choice: 'multiple_choice',
  pdf: 'pdf',
  checkbox_full: 'checkbox_full',
};

export const SIDE_CONST = {
  LEFT: 'Gauche',
  RIGHT: 'Droit',
};

export const CONTENT_TYPE_MAPPER = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  IMAGES: 'image/png, image/jpeg',
};
export const REVERS_CONTENT_TYPE_MAPPER = {
  [CONTENT_TYPE_MAPPER.PDF]: 'pdf',
  [CONTENT_TYPE_MAPPER.PNG]: 'png',
  [CONTENT_TYPE_MAPPER.JPG]: 'jpg',
  [CONTENT_TYPE_MAPPER.JPEG]: 'jpeg',
};


export const CRUD_MAPPER = {
  CREATE: 'create',
  UPDATE: 'update',
  READ: 'read',
  DELETE: 'delete',
};
