// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clinicalStudies: {
    medic: {
      sidemenu: {
        create_patient_btn: 'Create a patient',
        new_form_btn: 'Assign a form',
        store_btn: 'Templates',
      },
      list: { headers: { surgeryDate: 'Deadline for patient inclusion' } },
    },
    topMenu: { team: { label: 'Investigator Center:' } },
    medicPatient: { history: { list: { headers: { date: 'Deadline for signature' } } } },
    surveyPatientForm: {
      clinic_id: {
        label: 'Name of center',
        rules: { required: 'Please select a name for an investigative center' },
      },
      medical_teams_id: {
        label: 'Center #',
        rules: { required: 'Please select an investigator number' },
      },
      medic_surveys: {
        label: 'Composition of the investigator center',
        rules: { required: 'Investigator center required' },
      },
      referent_medic_id: {
        label: 'Investigator',
        rules: { required: 'An Investigator is required' },
      },
      intervention: {
        date_consult: { label: 'Date of consultation' },
        date: { label: 'Deadline for the end of patient inclusion' },
      },
    },
    formsScreen: {
      filters: {
        speciality: { title: 'Filter By Name Study' },
        type: { title: 'Filter By Type' },
      },
    },
    consentFormStep: {
      action: {
        'further information': { confirmation_message: 'In case of confirmation, your consent will be interrupted. Please contact your investigator so that they can answer your questions.' },
        Refuse: { confirmation_message: 'In case of confirmation, your participation in the clinical trial will be cancelled.' },
        RefuseWithPurpose: { confirmation_message: 'In case of confirmation, your participation in the clinical trial will be cancelled.' },
      },
    },
    ConsentRender: {
      rejection_message: {
        intro: 'You have stated that you want to cancel your participation in the clinical trial',
        'further information': 'You have stated that you want to cancel your participation in the clinical trial',
      },
    },

  },
  common: {
    ShowActionCounterSign: { toBeCountersignBy : 'Must be countersigned by {{medic}}' },
    InfoPatient: { List : { Status : { signatureDate : 'Signed on {{date}}' } } },
    noMatch: { disclamer: 'No match for {{pathname}}.' },
    form: {
      fields: {
        checkbox: { rules: { required: 'Please validate this field' } },
        security_number: {
          rules: {
            required: 'The social security number must be filled in.',
            size: 'The social security number must consist of 15 digits.',
            format: 'The social security number should only contain numbers.',
            not_valid: 'The social security number you entered is not valid.',
            key_not_valid: 'The key for the social security number entered is invalid.',
            sex: 'The first digit is incorrect',
          },
        },
      },
      button: {
        cancel: { label: 'cancel' },
        send: { label: 'Validate' },
        next: { label: 'Next' },
        previous: { label: 'previous' },
      },
      listSelectField: { more_info: 'more info' },
    },
    DropdownList: { placeholder: 'Choose an answer' },
    DocumentChoice: {
      title: {
        default: 'Documents',
        build_pdf: 'PDF consent',
        build_draw: 'Attachment',
        build_info: 'Documents',
        build_attachment: 'Attachment',
        question_pdf: 'PDF',
        edit_date: 'Change date',
        qr_code: 'On-site consent',
      },
      sliderMessage: { intervention_date_have_been_updated: 'The intervention date has been updated.' },
    },
    SummaryModal: {
      disclamer_header: 'Please take the time to read the answers carefully, then click the Continue button at the end of the page. As a reminder, you must have near you the mobile phone corresponding to the number {{phoneNumber}} on which an SMS with the code will be sent to you.',
      you_said: 'Analysis of your responses shows that your free and informed consent can be obtained. In fact, when you gave your consent, you declared:',
      answerList: {
        asked_more_info: 'After a reformulation, you answered',
        start: 'You answered',
        pdf: 'You have read_the_document',
        at_the_question: 'to the question: “{{question}}”.',
      },
    },
    summaryModal: {
      read_the_document: 'read_the_document',
      at_the_question: 'to the question',
      summary_end: 'The questionnaire you have just completed is obviously not exhaustive. A particularly exceptional complication can always occur, linked to a singular and unknown state of health, to the local state or to technical variability. Not all complications can be specified, which you understand and accept.',
    },
    ConsentRender: {
      answer: { required: 'Response required' },
      dropdown_list: { required: 'Response required' },
      multiple_choice: { required: 'Response required' },
      text_answer: { required: 'Response required' },
      checkbox_full: { required: 'To be able to sign your consent, all the boxes must be checked.' },
      pdf: { isRead: 'The PDF should be read in its entirety.' },
      validation: { required: 'You must validate the question in order to continue' },
      rejection_message: {
        intro: 'You have declared that you do not want to continue your consent',
        question: 'Indeed, despite the additional explanations, you did not understand the question {{question}}',
        refused: 'You have declared that you do not want to pursue your consent',
        'further information': 'You have stated that you need additional information to sign your consent',
      },
    },
    signatureModal: {
      title: 'Signing your consent',
      header: {
        text: 'I, the undersigned {{signatory}}, am the author of the responses to the online questionnaire {{signature_ip_address}} on {{fromDate}} from {{fromTime}} to {{toTime}}, and validate all of my responses.',
        ip_address: 'under the ip address {{ipAddress}}',
      },
      step0: { text: 'An <bold>SMS</bold> will be sent to you<br/><br/> that will contain the code that will allow you to sign your consent. You will need to enter it in the form at the end of the next_page. <br/><br/>Please click on <bold>continue</bold> to receive it<br/>' },
      step1: {
        your_code_here: 'Your SMS code',
        send_code_too_many_tries: "You've tried too many times. Please try again later or contact support.",
        send_new_code_disclaimer: 'You will be able to request a new code in {{second}} sec',
        input: { placeholder: 'Enter the code' },
      },
      step2: {
        header_text: 'Thanks for using Easy-Consent.',
        text: 'You can find your documents in your personal space. The practitioner {{medicName}} has also just received your consent',
      },
      step3: { text: 'There was an error signing your consent. Please contact Easy-Consent Technical Support' },
    },
    medicCounterSignModal: { thanks_message: 'Thanks for using Easy-Consent.' },
    medicPatient: {
      history: {
        title: 'History of forms',
        list: {
          headers: {
            name: 'Form name',
            refNumber: 'Number',
            date: 'Signature deadline',
            status: 'Status',
            documents: 'Documents',
            action: 'Action',
            tutorName: 'Rep',
          },
          status: {
            patient: {
              core: {
                countersigned: 'countersigned',
                accepted: 'signed',
                sent: 'in progress',
                'further information': 'info supp',
                refused: 'refused',
                draft: 'in progress',
                not_signed: 'Unsigned',
                withdrawal: 'Withdrawn',

              },
              clinicalStudy: {
                countersigned: 'countersigned',
                accepted: 'signed',
                sent: 'in progress',
                'further information': 'info supp',
                refused: 'refused',
                draft: 'in progress',
                not_signed: 'Unsigned',
                withdrawal: 'withdrawal',
              },
            },
            pro: {
              core: {
                countersigned: 'signed',
                accepted: 'signed',
                sent: 'in progress',
                'further information': 'info supp',
                refused: 'refused',
                draft: 'in progress',
                not_signed: 'Unsigned',
                withdrawal: 'Withdrawn',

              },
              clinicalStudy: {
                countersigned: 'signed',
                accepted: 'to countersign',
                sent: 'in progress',
                'further information': 'info supp',
                refused: 'refused',
                draft: 'in progress',
                not_signed: 'Unsigned',
                withdrawal: 'withdrawal',
              },
            },
          },
          actionBtn: {
            attachments: 'See',
            parameters: 'Settings',
            signed: 'signed',
            countersigned: 'countersigned',
            not_signed: 'Unsigned',
            sent: 'in progress',
            toSign: 'To sign',
            refused: 'refused',
            countersign: 'Counter sign',
            resume: 'Relaunch',
            delete: 'delete',
            archive: 'Archive the patient',
            'further information': 'Info',
            withdrawal: 'Withdraw consent',
          },
        },
      },
    },
    medic: {
      sidemenu: {
        create_patient_btn: 'Create a patient',
        new_form_btn: 'Assign a form',
        store_btn: 'Templates',
        filters: {
          local_phone_number: { title: 'Phone number' },
          phone_number: { title: 'Phone number' },
          security_number: { title: 'Social security number' },
          lastname: { title: 'Birth name' },
          firstname: { title: 'Firstname' },
          birthdate: { title: 'Birthdate' },
          surveys: { title: 'Intervention date' },
          fullName: { title: 'FullName' },
          fullNameReversed: { title: 'FullNameReversed' },
        },
      },
      filters: {
        title: 'Display',
        consent: 'consents',
        patient: 'patients',
        surgery: 'interventions',
      },
      stats: {
        clinicalStudy: {
          countersigned: 'finalized',
          accepted: 'to countersign',
          in_progress: 'in progress',
          'further information': 'Additional information',
          refused: 'refusals',
          not_signed: 'Unsigned',
          withdrawal: 'Withdrawal',

        },
        hospitalisation: {
          accepted: 'signed',
          in_progress: 'in progress',
          refused: 'additional information',
          not_signed: 'Unsigned',
          withdrawal: 'Withdrawal',

        },
      },
      list: {
        'Loading...': 'Loading...',
        headers: {
          patient: 'Patient',
          surgeryDate: 'Intervention',
        },
      },
    },
    surveylist: {
      filters: {
        date: { title: 'Date' },
        type: { title: 'Type' },
        number: { title: 'Number' },
        status: { title: 'Status' },
        title: { title: 'Title' },
      },
      configStatus: {
        countersigned: 'countersigned',
        accepted: 'signed',
        sent: 'in progress',
        'further information': 'info supp',
        refused: 'refused',
        draft: 'in progress',
        not_signed: 'Unsigned',
        withdrawal: 'Withdrawn',
      },
    },
    topMenu: {
      dropdown: {
        profil: 'My profile',
        logout: 'Disconnect',
      },
      team: { label: 'Team:' },
    },
    auth: {
      forgotten_pswd: {
        label: 'Forgotten password or not received',
        Modal: {
          titleStep_0: 'Forgot password or not received?',
          titleStep_1: 'Forgot password or not received?',
          titleStep_2: 'Confirm your identity',
          titleStep_3: 'Resetting your password',
        },
      },
      title_pro: 'Professional space',
      title_patient: 'I am a patient',
      form: {
        username: {
          label_pro: 'Identifier',
          label_patient: 'Identifier',
        },
        password: { label: 'password' },
        submit: { label: 'Sign In' },
      },
    },
    formsScreen: {
      list: { 'Loading...': 'Loading...' },
      filters: {
        speciality: { title: 'Filter by specialty' },
        type: { title: 'Filter By Type' },
      },
    },
    richtext: {
      preview: 'Overview',
      click_here: 'Click here to edit the text',
    },
    all: 'All',
    blockedModal: { disclamer_portrait_orientation: 'Please note, software interface optimized for landscape orientation. Please rotate your tablet horizontally.' },
    and: 'and',
    no_information: 'Not Entered',
    unauthorized: 'You are not authorized to view this page, please log in.',
    authentication_failed: 'Incorrect password and/or login.',
    authentication_wrong_code: 'Wrong code.',
    authentication_pwd_changed: 'Password changed successfully.',
    reset_pwd_success: 'Password changed successfully. Please reconnect',
    logout_error: 'You have been automatically disconnected, please reconnect',
    logout_success: 'Logout Done',
    generic_error: 'An unknown error occurred while loading data.',
    fields_missing: 'Please fill in all the fields on the form.',
    survey_create_success: 'Successfully created form',
    surveys_list_error: 'Failed to retrieve forms',
    surveys_types_error: 'Failed to retrieve types',
    surveys_specialty_error: 'Failed to retrieve specialties',
    surveys_question_type_error: 'Failed to retrieve question types',
    surveys_create_error: 'Failed to create form',
    surveys_create_error_large_file: 'Failed to create the form due to an image that is too large',
    survey_current_error: 'Error retrieving form',
    survey_current_success: 'Recovery of your form done',
    survey_current_update_error: 'Error saving your answers',
    survey_update_success: 'Form updated successfully',
    survey_update_error: 'An error occurred in updating the form',
    survey_sign_success: 'Consent signed successfully',
    survey_sign_error: 'An error occurred while signing your consent, please contact secretariat',
    surveys_delete_error: 'An error occurred in archiving the form',
    surveys_delete_success: 'Completed archiving of the form',
    survey_withdrawal_error: 'An error occured in withdrawing consent',
    survey_withdrawal_success: 'Completed withdrawing of the consent',
    upload_sign_success: 'PDF recovery done',
    upload_sign_error: 'Failed to recover PDF',
    patient_list_error: 'An error occurred during the recovery of your patients',
    patient_current_error: 'An error occurred while retrieving your data',
    create_patients_error: 'The creation of the patient failed',
    patients_detail_error: 'Failed to retrieve patient data',
    your_commentary: 'Your comment',
    a_step_translation: 'A step',
    reorder_translation: 'Reorder',
    more_options: 'more options',
    add_a_pdf_translation: 'A PDF',
    previous: 'previous',
    reorder_your_form: 'Reorganize your form',
    current_medic_error: 'Error retrieving your information',
    update_medic_error: 'Error editing your information',
    update_medic_success: 'Your information has been edited',
    template_update_error: 'Failed to edit the current form',
    template_update_error_large_file: 'Editing the current form failed due to an image that was too large',
    template_update_success: 'The edition of the current form is carried out',
    forgotten_password_too_many_tries: "You've tried too many times. Please try again later or contact support.",
    unkown_abbreviation: null,
    mister: 'sir',
    miss: 'madam',
    unkown: null,
    born_he: 'born',
    born_she: 'born',
    change_pwd: 'Creating your password',
    verif_first_connexion: 'Checking your information',
    hello: 'hello',
    accept_cgu: 'Terms and Conditions',
    accept_rgpd: 'Confidentiality policy',
    accepted: 'Accept',
    refused: 'Decline',
    cgu_deny: 'Are you sure? You will be redirected to the login page',
    cgu_checkbox_label: 'I have read and I accept the T&Cs',
    cgu_checkbox_label_withlink: "I have read and accept <a href='{{ href }}' target='_blank' rel='noopener noreferrer'>the terms and Conditions</a> (T&Cs)",
    rgpd_checkbox_label: 'I have read and accept the privacy policy',
    rgpd_checkbox_label_withlink: "I have read and accept <a href='{{ href }}' target='_blank' rel='noopener noreferrer'>the privacy policy</a>",
    cgu_checkbox_error: 'Please check the box',
    rgpd_checkbox_error: 'Please check the box',
    medic_template_delete_success: 'Deleted your form',
    medic_template_delete_error: 'Error deleting your form',
    attachment: 'Attachment',
    draw: 'drawing',
    consent: 'Consent',
    edit_date: 'Change date',
    review_consent: 'Review of consent',
    generate_qr_code: 'On-site consent',
    sign_in: 'I IDENTIFY MYSELF',
    connect: 'LOGIN',
    dashboard: 'Dashboard',
    skip_form: 'Cancel creation',
    validate: 'Validate',
    Continue: 'continue',
    Refuse: 'Decline',
    'further information': 'Would like more information',
    continue: 'proceed',
    return: 'back',
    undo: 'cancel',
    download: 'Download',
    download_the_attachment: 'Download attachment',
    confirm: 'Confirm',
    change_form: 'Change form',
    add: 'Add',
    add_attachment: 'Add an attachment',
    delete_attachment: 'Erase file',
    see_attachment: 'View your attachment',
    choose_file: 'Choose a file',
    add_an_image: 'Add an image',
    no_background: 'No background image',
    return_to_image: 'Back to the image',
    restart: 'start over',
    make_a_drawing: 'Make your drawing',
    see_a_drawing: 'Visualize your drawing',
    search: 'Search',
    cancel: 'Undo',
    reset: 'Reset',
    next: 'following',
    finish: 'Finish',
    add_a_tutor: 'Add a legal representative',
    edit: 'Edit',
    yes: 'yes',
    no: 'no',
    Yes: 'Yes',
    No: 'No',
    the: 'the',
    at: 'unto',
    from: 'of',
    one_male: 'One',
    Gauche: 'Left',
    Droit: 'Right',
    phone_text_message: 'SMS',
    delete_draw: 'Delete drawing',
    consent_refused: 'Form refused',
    pdfViewer: {
      previous_page: 'Previous page',
      next_page: 'Next page',
      Page_x_on_xx: 'Page {{page}} of {{pages}}',
    },
    paint: {
      brushRadius: { label: 'brush size: {{radius}}' },
      btn: {
        reset: 'Erase',
        undo: 'Undo',
        delete_draw: 'Delete drawing',
      },
    },
    surveyCreateForm: {
      button: {
        cancel: { label: 'cancel' },
        send: { label: 'Validate' },
      },
      menu: {
        actionBtn: {
          addStep: { tooltip: 'Add a step' },
          reorder: { tooltip: 'Reorder' },
          upload: { tooltip: 'Add a PDF' },
        },
      },
      header: {
        medicTeamDiscaler: 'The Form will be created in the medical team: <b>{{medicTeamTitle}}</b>',
        title: {
          placeholder: 'Title of the form',
          rules: { required: 'Title must be filled in.' },
        },
        type: { placeholder: 'For what type of intervention' },
        speciality: { placeholder: 'For which specialty' },
        description: { placeholder: 'Tag' },
        isSide: { label: 'Choice of the side' },
        side: { label: 'Choose the side' },
        isRadius: { label: 'Choice of radius' },
        radius: { label: 'Choose the radius' },
      },
      title: {
        label: 'Title of the form',
        rules: {
          required: 'A Title must be filled in.',
          format: 'Title should contain letters only.',
        },
      },
      type: {
        label: 'For what type of intervention',
        rules: { required: 'The type of intervention must be filled in.' },
      },
      survey_pdfs: {
        rules: {
          size: 'The file is too big',
          format: 'Only PDFs are accepted',
        },
      },
      steps: {
        rules: { min: 'A form must contain at least one step.' },
        title: {
          rules: {
            required: 'Title must be filled in.',
            format: 'The title should only contain letters.',
          },
        },
        comment: {
          rules: {
            required: 'The detail of the question must be filled in.',
            format: 'The detail of the question is not in the right format.',
          },
        },
        questions: {
          rules: { min: 'A step must contain at least one question.' },
          question: {
            rules: {
              required: 'Title must be filled in.',
              format: 'The title should only contain letters.',
            },
          },
          desc: { rules: { required: 'The detail of the question must be filled in.' } },
          validation: { first: { required: 'The field must be filled in.' } },
        },
      },
    },
    authForm: {
      username: {
        rules: {
          notAnEmail: 'The username field should not be an email',
          required: 'The username field must be filled in.',
        },
      },
      password: {
        placeholder: 'Enter your new password',
        rules: {
          min: 'At least 12 characters',
          required: 'The password must be filled in.',
        },
      },
    },
    resetPswdForm: {
      password: {
        placeholder: 'Enter your new password',
        rules: {
          required: 'The password must be filled in.',
          min: 'At least 12 characters',
          minOneMaj: 'At least 1 capital letter',
          minOneMinuscule: 'At least 1 lowercase',
          minOneNumber: 'At least 1 number',
          minOneSpecialChar: 'At least 1 special character',
        },
      },
      passwordConfirmation: {
        placeholder: 'Confirm the new password',
        rules: { same: 'Passwords must be the same' },
      },
    },
    otpForm: {
      title: 'Enter the code received by email',
      text: 'To verify your identity, we have sent you a one-time code by email. Le code OTP est valable uniquement pendant dix (10) minutes après l\'avoir reçu sur votre e-mail enregistré.',
      submit: { label: 'Connect' },
      cancel: { label: 'Cancel' },
      code: {
        placeholder: 'Enter the otp code from your emails',
        rules: { required: 'The code must be filled in.' },
      },
    },
    modalRenewForm: {
      username: {
        description_text: 'Please provide your identifier, which was communicated in the email sent by Easy-consent. Thank you.',
        description_text_pro: 'Please provide your email or your identifier, which was communicated in the email sent by Easy-consent. Thank you.',
        go_to_email: "I don't know my login",
        rules: {
          required: 'The ID must be filled in.',
          username: 'The ID must be filled in.',
        },
      },
      email: {
        description_text: 'Please enter your email address',
        rules: {
          required: 'The email must be filled in.',
          email: 'The email must be filled in.',
        },
      },
      code: {
        placeholder: 'Enter the security code',
        rules: { required: 'The code must be filled in.' },
      },
      password: {
        placeholder: 'Enter your new password',
        rules: {
          required: 'The password must be filled in.',
          min: 'At least 12 characters',
          minOneMaj: 'At least 1 capital letter',
          minOneMinuscule: 'At least 1 lowercase',
          minOneNumber: 'At least 1 number',
          minOneSpecialChar: 'At least 1 special character',
        },
      },
      passwordConfirmation: {
        placeholder: 'Confirm the new password',
        rules: { same: 'Passwords must be the same' },
      },
    },
    medicForm: {
      lastname: {
        label: 'Birth name',
        rules: {
          required: 'The last name must be filled in.',
          format: 'The last name should contain letters only.',
        },
      },
      firstname: {
        label: 'First name',
        rules: {
          required: 'The first name must be filled in.',
          format: 'The first name should contain letters only.',
        },
      },
      phone_number: {
        label: 'Landline phone',
        rules: {
          format: 'The landline number entered is not valid.',
          required: 'The landline number must be filled in.',
        },
      },
      mobile_phone_number: {
        label: 'Mobile phone',
        rules: {
          format: 'The mobile number entered is not valid.',
          required: 'The mobile number must be filled in.',
        },
      },
      rpps_number: {
        label: 'RPPS number',
        rules: {
          format: 'The RPPS number entered is composed of 11 numeric characters and starts with 10',
          required: 'RPPS number must be filled in.',
        },
      },
      country: {
        label: 'country',
        rules: {
          format: 'The country entered is not valid.',
          required: 'The country must be filled in.',
        },
      },
      language: {
        label: 'Language',
        rules: {
          format: 'Language is not valid',
          required: 'Language must be filled in.',
        },
      },
    },
    uploadForm: {
      file: {
        rules: {
          required: 'Please uplaod a file',
          too_large: 'File too large',
          unsupported_format: 'Unsupported format',
        },
      },
    },
    surveyTemplateForm: {
      template: { rules: { required: 'Please select a template' } },
      type_id: {
        label: 'Type',
        rules: { required: 'Please make your choice among the types available' },
      },
      switch: {
        single: 'Simple attribution',
        multiple: 'Multiple attribution',
      },
    },
    surveyPatientForm: {
      patient_id: {
        label: 'Patient',
        rules: { required: 'Please select a patient' },
      },
      clinic_id: {
        label: 'Hospital',
        rules: { required: 'Please select an hospital' },
      },
      medical_teams_id: {
        label: 'Medical team',
        rules: { required: 'Please select a medical team' },
      },
      medic_surveys: {
        label: 'Composition of the medical team',
        rules: { required: 'The medical team is required' },
      },
      referent_medic_id: {
        label: 'Referral practitioner',
        rules: { required: 'A referring practitioner is required' },
      },
      intervention: {
        date_consult: {
          label: 'Date of consultation',
          rules: {
            date: 'Date is required',
            format: 'The date is not in the correct format DD/MM/YYYY',
            required: 'Date is required',
            min: 'The date is too far',
            max: 'The date is too far',
          },
        },
        date: {
          label: 'Date of the act',
          rules: {
            date: 'Date is required',
            format: 'The date is not in the correct format DD/MM/YYYY',
            required: 'Date is required',
            min: 'The date is too far',
            max: 'The date is too far',
            manual: 'The date must be after the consultation',
          },
        },
      },
      date_warning: {
        desc: "<p><span style='color: rgb(230, 0, 0);'>Attention, you have just scheduled a treatment in {{daysToAct}} days, i.e. a delay of less than 16 days. </span></p>We remind you that it is legally usual to give your patients a reflection period of 15 days between the date of the consultation and the date of the intervention. <br/><br/>This convenience period corresponds to the reflection time considered necessary for complete adherence of the patient. <br><br>For example: <br>Date of consultation on <strong>{{date_consult}}</strong> -> <strong>15 days</strong> of reflection <strong>between</strong> consultation and operation -> date of operation on <strong>{{theorical_date_act}}</strong>. <br><br>However, in the case of your patient, {{contactNameWithTitle}}, it may be:",
        options: {
          first: 'a medically justifiable emergency',
          second: 'a specific request from the patient',
          third: 'an agreement between the two parties',
        },
      },
      supInfoForm: {
        side_answer: {
          label: 'Choose the side',
          rules: {
            format: null,
            required: null,
            min: 'The side should be filled in.',
            max: 'Please choose one or two sides',
          },
        },
        radius_answer: {
          label: 'Choose the radius',
          rules: {
            format: null,
            required: null,
            min: 'The radius must be filled in.',
          },
        },
      },
      uploadForm: {
        title_paper: {
          label: 'Title of the document',
          rules: {
            format: null,
            required: null,
          },
        },
        file_paper: {
          label: 'Upload your file',
          rules: {
            format: null,
            required: null,
          },
        },
      },
    },
    patientForms: {
      modal: {
        create_patient: {
          title: 'Creating a patient',
          titleWithTeam: 'Creating a patient<br/> {{medicTeamTitle}}',
        },
        create_tutor: {
          title: 'Creation of a legal representative',
          titleWithTeam: 'Creation of a legal representative<br/> {{medicTeamTitle}}',
        },
      },
      button_choice: {
        tutor: {
          title: 'under tutorship',
          subTitle: 'patient',
        },
        independant: {
          title: 'independent',
          subTitle: 'patient',
        },
      },
      button: { add_tutor: { label: 'Add a legal representative' } },
      gender: {
        label: 'Gender',
        rules: { required: 'The gender has to be filled in.' },
      },
      language_id: {
        label: 'Language',
        rules: { required: 'The Language has to be filled in.' },
      },
      security_number: {
        label: 'Social security number',
        rules: {
          required: 'The social security number must be filled in.',
          size: 'The social security number must consist of 15 digits.',
          format: 'The social security number should only contain numbers.',
          not_valid: 'The social security number you entered is not valid.',
          key_not_valid: 'The key for the social security number entered is invalid.',
        },
      },
      lastname: {
        label: 'Birth name',
        rules: {
          required: 'The last name must be filled in.',
          format: 'The last name should contain letters only.',
        },
      },
      use_name: {
        label: 'Usage name',
        rules: {
          required: 'The usage name should only contain letters.',
          format: 'The usage name should only contain letters.',
        },
      },
      firstname: {
        label: 'First name',
        rules: {
          required: 'The first name must be filled in.',
          format: 'The first name should contain letters only.',
        },
      },
      birthdate: {
        label: 'Date of birth',
        rules: {
          date: 'The requested date does not exist.',
          required: 'The date must be filled in.',
          format: 'Date format should be. DD/MM/YYYY',
          max: 'Cannot enter a date of birth later than today.',
        },
      },
      birthplace_postcode: {
        label: 'Zip code of birth',
        rules: {
          required: 'Zip code must be filled in.',
          format: 'The zip code entered is not French.',
        },
      },
      birthplace_id: {
        label: 'City of birth',
        rules: {
          required: 'The city must be filled in',
          format: 'The city should only contain letters.',
        },
      },
      birthplace: {
        label: 'City of birth',
        rules: {
          required: 'The city must be filled in',
          format: 'The city should only contain letters.',
        },
      },
      city_postcode: {
        label: 'Zip code',
        rules: {
          required: 'Zip code must be filled in.',
          format: 'The zip code entered is not French.',
        },
      },
      city_id: {
        label: 'City of residence',
        rules: {
          required: 'The city must be filled in',
          format: 'The city should only contain letters.',
        },
      },
      city: {
        label: 'City of residence',
        rules: {
          required: 'The city must be filled in',
          format: 'The city should only contain letters.',
        },
      },
      phone_number: {
        label: 'Mobile phone',
        rules: {
          format: 'The mobile number entered is not valid.',
          required: 'The mobile number must be filled in.',
        },
      },
      email: {
        label: 'E-Mail',
        rules: {
          required: 'The email address must be filled in.',
          email: 'The email address entered is not valid.',
          unavailable: 'The email address entered is not available.',
        },
      },
      email_confirm: {
        label: 'Email (Verification)',
        placeholder: 'email@service.com',
        rules: {
          required: 'Please confirm the email address.',
          must_be_the_same: "Email addresses don't match.",
        },
      },
    },
    medic_templatesList: {
      list: null,
      item: {
        popup: {
          delete: {
            title: 'Are you sure you want to delete this form?',
            iconTooltip: 'Read only',
            iconTooltipWithName: 'Read only, belongs to Dr {{lastname}}',
          },
        },
      },
      card: {
        date: { prefix: 'Created on {{date}}' },
        actions: { add_fom: { title: 'Add the form' } },
      },
    },
    side_require: 'The side should be filled in.',
    radius_require: 'The radius must be filled in.',
    username_require: 'The ID must be filled in.',
    patient_require: 'A patient must be informed.',
    etablissement_require: 'The hospital must be filled in.',
    type_require: 'The type of intervention must be filled in.',
    empty_list: 'Empty list',
    past_date: 'The date entered must be later than today.',
    invalid_date: 'Invalid date.',
    medical_team_require: 'The medical team must be informed.',
    medical_referent_require: 'The referring practitioner must be informed.',
    home_phone_format: 'The phone number you entered is not valid.',
    login_patient: 'I am a patient',
    accueil_connect: 'Sign in',
    accueil_first_connect: 'First connection',
    accueil_min_text: 'I am',
    accueil_patient_text: 'a patient',
    acceuil_patient_text_uppercase: 'A patient',
    acceuil_menu_add_form: 'A form',
    accueil_medecin_text: 'professional',
    accueil_medecin_min_text: 'Space',
    modal_create_interv_form: 'Assigning a form',
    modal_edit_medic_form: 'Profile editing',
    countersign_invalid_number_redirect: 'You will be redirected to the countersignature window after validating your phone number.',
    countersign_invalid_number: 'You must enter a valid phone number to complete a <b>Countersignature</b>',
    edit_phone_number: 'Edit my Phone number',
    username_placeholder: 'Identifier',
    email_placeholder: 'E-mail address',
    password_placeholder: 'Password',
    invalid_credentials: 'Invalid username and/or password',
    forgotten_password: 'Forgot password',
    forgotten_password_step1: 'Forgot your password?',
    forgotten_password_step2: 'Confirm your identity',
    forgotten_password_step3: 'Resetting your password',
    forgotten_password_code: 'If the information entered corresponds to an account, you will receive a validation code by SMS',
    first_connexion: 'First connection',
    first_connexion_reset_pwd: 'Creating your password',
    first_connexion_desc: 'If this is your first connection please enter the code that was sent to you by SMS',
    password_modify_error: 'Failed to change your password',
    password_modify_success: 'Your password has been successfully changed',
    contentheader_title_form: 'Title of the form',
    auth_header_profile: 'My profile',
    auth_header_logout: 'Disconnect',
    patient_list_patient: 'Patient',
    patient_list_surgery: 'Intervention',
    filter_card_display: 'Display',
    filter_card_surgery: 'interventions',
    filter_card_consent: 'consents',
    filter_card_patient: 'patients',
    stat_card_in_progress: 'in progress',
    stat_card_reformulated: 'reformulations',
    stat_card_signed: 'signatures',
    stat_card_refused: 'refusals',
    patient_card_title: 'Patient info',
    patient_card_empty_list: 'No patients',
    patient_card_empty_list_surgery: 'No intervention',
    patient_card_empty_list_consent: 'No consent',
    patient_card_empty_list_patient: 'No patients',
    patient_card_birthdate: 'Birth Date',
    patient_card_birthplace: 'birthplace',
    patient_card_legal: 'Legal representation',
    patient_card_intervention_securite: 'Social security number',
    patient_card_intervention_gender: 'Gender',
    patient_card_intervention_number: 'number:',
    patient_card_birthname: 'Birth name:',
    research: 'Research',
    valid_document: 'Validation of the document',
    invalid_number: 'Invalid phone number',
    modal_contact_support_title: 'Contact support',
    modal_contact_support_body: 'Please contact customer service to add you to a medical team',
    male: 'man',
    female: 'woman',
    Homme: 'Man',
    Femme: 'woman',
    form_card_add_title: 'New form',
    form_card_item_date: 'Created on',
    form_card_search_placeholder: 'Search for a form',
    form_card_search_placeholder_medic: 'Search for a practitioner form',
    form_card_search_placeholder_store: 'Search for a store form',
    form_screen_second_list_title: 'Forms Available',
    create_patient_success: 'Patient created',
    question_types: 'Types of questions',
    question_type_validation: 'Validation',
    question_type_text_answer: 'Text response',
    question_type_multiple_choice: 'Multiple choice',
    question_type_dropdown_list: 'Dropdown list',
    question_type_pdf: 'PDF',
    question_type_checkbox_full: 'Checkboxes',
    cancel_form_creation: 'Cancel creation',
    form_step_title: 'Stage title',
    form_screen_slide_a_componant: 'Drag a component here',
    form_screen_add_a_componant: 'Click to add a component here',
    form_screen_write_details: 'Add a reformulation',
    form_screen_delete_question: 'Delete the question',
    form_screen_title_required: 'required',
    form_screen_type_required: 'required',
    form_screen_speciality_required: 'required',
    rewording_card_title: 'Reformulation',
    answer: 'Answer',
    radius_choice: 'Choice of radius',
    side_choice: 'Choice of the side',
    medic_assign_from_store_error: 'Error assigning form from store',
    medic_assign_from_store_success: 'Assignment of the form from the store done',
    medic_unassign_from_store_success: 'Deleted the form',
    medic_unassign_from_store_error: 'Error deleting form',
    hide_template_store: 'My forms',
    display_template_store: 'Store',
    confirm_delete_template: 'Are you sure you want to delete this form?',
    confirm_archive_patient: 'Are you sure you want to remove this patient? You won’t be able to go back',
    confirm_withdrawal_consent: 'Do you really want to withdraw your consent ? You won’t be able to go back.',
    confirm_delete_steps: 'Adding a PDF will remove the steps. Are you sure?',
    confirm_change_lng: "You changed aren't saved. Do you confirm changing the language?",
    upload_document: 'Load new document',
    delete_document: 'Delete the document',
    hide_document: 'Hide document',
    show_document: 'See the document',
    document_loading: 'Your document is loading...',
    error_create_form_medic_team: 'Cannot create a new form. You do not belong to any medical team.',
    card_button_numerique: 'A digital act',
    card_button_papers: 'A paper act',
    patient_multiple_choice_error_message: 'To be able to sign your consent, all the boxes must be checked. If you do not want to check one of these boxes, you can click “{{furtherBtnText}}” or “{{refuseBTNText}}” located at the bottom of your screen. Your consent will be interrupted.',
    patient_screen: { interventionsList: { title: 'History of forms' } },
    btn: {
      edit: 'Edit',
      edit_the_informations: 'Edit information',
    },
    patient_button_edit: 'Edit',
    patient_button_relancer: 'Relaunch',
    patient_button_upload: 'Upload',
    patient_button_delete: 'delete',
    patient_button_contact: 'contact',
    patient_text_contacts: 'Patient contacts',
    patient_text_phone: 'Phone',
    patient_text_email: 'Email',
    patient_text_username: 'Identifier',
    patient_text_address: 'Adress',
    patient_text_interventions: 'History of forms',
    patient_title_type: 'Form name',
    patient_title_numero: 'number',
    patient_title_date: 'date',
    patient_title_status: 'Status',
    patient_title_documents: 'Documents',
    patient_title_action: 'Action',
    patient_title_representant: 'Rep',
    patient_item_voir: 'See',
    patient_intervention_signed: 'signed',
    patient_intervention_countersigned: 'countersigned',
    patient_intervention_sended: 'in progress',
    patient_intervention_to_sign: 'sign',
    patient_intervention_unsigned: 'refused',
    patient_item_contre_signature: 'Counter sign',
    countersign_modal: 'Countersignature',
    patient_edit_error: 'Error editing patient',
    patient_edit_success: 'Successfully edited patient',
    surgeon: 'Surgeon',
    establishment: 'Hospital',
    pathology: 'Pathology',
    date_acte: 'Medical act scheduled on',
    commentary: 'Commentary',
    no_commentary: 'There are no comments associated with this form.',
    preview_answers: 'Viewing your answers',
    summary_details_first_part: 'Please take the time to read the answers carefully, then click the Continue button at the end of the page. As a reminder, you must have the mobile phone corresponding to the number near you',
    summary_details_last_part: 'on which an SMS with the code will be sent to you.',
    sumary_details_patient_part: 'Analysis of your responses shows that your free and informed consent can be obtained. In fact, when you gave your consent, you declared:',
    medic_signature: 'I, the undersigned {{medic}} investigator at the center {{clinic}}, declare to countersign the consent signed by the patient on {{date}}.',
    medic_signature_text_one: 'I, the undersigned',
    medic_signature_text_two: 'investigator of the clinical research protocol',
    medic_signature_text_three: ', declares to countersign the consent signed by the patient',
    tutors_text_contacts: 'Legal representative contacts',
    tutors_text_name: 'Name',
    profil_coordonnees: 'Coordinates',
    profil_id_connexion: 'Login credentials',
    profil_specialites: 'Specialities',
    no_specialites_disclaimer: 'No specialty. Get closer to the technical department',
    no_medic_team_disclaimer: 'No medical team. Get closer to the technical department',
    profil_notifications: 'Notifications',
    patient_update_cgu_error: 'You have just refused the T&Cs',
    patient_update_cgu_success: 'You have accepted the T&Cs',
    patient_update_rgpd_error: 'You have just refused the privacy policies',
    patient_archive_success: 'The patient has been removed',
    patient_archive_error: 'You don\'t have the permission to remove this patient',
    patient_update_rgpd_success: 'You have accepted the privacy policies',
    patient_update_edit_info_error: 'Error updating information',
    medic_fetch_metrics_error: 'error retrieving statistics',
    info_name: 'Name',
    info_rpps: 'RPPS NO.',
    info_inscription: 'Enrollment Department',
    info_country: 'Country',
    info_language: 'Language',
    idconnection_notification: 'Receive notifications by',
    idconnection_email: 'Email',
    notification_text_sms: 'SMS',
    notification_text_email: 'Email',
    specialite_title_pathologies: 'Pathologies',
    specialites_pathologies_active: 'pathologies are activated',
    medicals_teams_title: 'Medical teams',
    medicals_teams_label: 'Team:',
    practitioner: 'Practitioner',
    no_characteristique_chosen: 'No features are enabled',
    consent_preview_intervention: '{{patientDisplay}} consent is well created!',
    consent_preview_creation: 'is well created!',
    consent_preview_consentement: 'Consent',
    consent_preview_locally: 'onsite',
    consent_preview_home_based: 'at home',
    consent_preview_intervention_date: 'The deadline for signing the consent is {{interventionDate}}',
    consent_preview_procedure: 'so that he can finish the procedure.',
    consent_preview_mail: 'An email will be sent to {{patientDisplay}} so that it can complete the procedure.',
    consent_preview_merci: 'Thanks',
    consent_tutor_is: 'Procedure guardian: {{tutorDisplay}}',
    consent_or: 'OR',
    update_info_title: 'Update contact info {{displayName}}',
    patient_survey_resume_error: 'Failed to send email, please try again later or contact support',
    patient_survey_resume_success: 'An email has just been sent to the patient',
    patient_informations: 'Your information',
    patient_tutored: 'Tutor of',
    patient_contact: 'Contact',
    patient_no_tutored: 'Autonomous patient',
    empty_tab_patient: 'No interventions yet',
    empty_cache: 'Empty browser cache',
    password: {
      rules: {
        number_character: 'At least 12 characters',
        upper: 'At least 1 capital letter',
        lower: 'At least 1 lowercase',
        number: 'At least 1 number',
        special_character: 'At least 1 special character',
      },
      strength: {
        Veryweak: 'Very weak',
        Weak: 'Weak',
        Good: 'Good',
        Strong: 'Strong',
      },
    },
    identity_modal_title: 'Verifying your identity',
    verify_identity_error: 'Be careful, it seems that you are not the person corresponding to the password you entered.',
    verify_identity_error_confirm: 'You confirm to us that you are not:',
    verify_identity_error_first_choice: '1. I made a typing error and I want to return to the question',
    verify_identity_error_second_choice: '2. Do you confirm that you want to stop the procedure?',
    verify_identity_error_first_button: 'Back to the question',
    verify_identity_error_second_button: 'YES, I confirm',
    verify_identity_error_stop_consent: 'In case of confirmation, your consent will be interrupted, you must contact your practitioner because your intervention will be cancelled.',
    verify_identity_success_confirm: 'As part of the identitovigilance procedures, you have confirmed your identity to us.',
    verify_identity_success_consent_begin: 'The procedure for obtaining free and informed consent can therefore begin.',
    complementary_info: 'Additional information',
    consent_modal_end: 'Now that you have been able to get this additional information, do you understand?',
    consent_modal_title: 'Be careful, you did not understand the following question:',
    consentFormStep: {
      action: {
        'further information': {
          btn: 'Would like more information',
          confirmation_message: 'In case of confirmation, your consent will be interrupted. Please contact your practitioner so that they can answer your questions.',
        },
        previous: { btn: 'previous' },
        return_patient: { btn: 'Go back to the Patient file' },
        Continue: { btn: 'continue' },
        Refuse: {
          btn: 'Decline',
          confirmation_message: 'In case of confirmation, your consent will be interrupted, you must contact your practitioner as soon as possible to explain what you did not understand.',
        },
        ConfirmRefuse: { message: 'Do you confirm ?' },
        RefuseWithPurpose: {
          'message': 'Please state the reason for your refusal to participate.',
          btn: 'Decline',
          confirmation_message: 'In case of confirmation, your appointment with the practitioner may be cancelled.',
        },
      },
    },
    understand: 'Yes, I understand',
    not_understand: 'No, I did not understand',
    sign_your_consent: 'Signing your consent',
    send_code_back: 'Resend the code',
    send_code_back_in_given_time: 'Resend a code in {{time}} s',
    send_code_too_many_tries: "You've tried too many times. Please try again later or contact support.",
    consent_signed: 'Consent signed',
    consent_error: 'Signature error',
    forbidden: 'Access forbidden',
    forbidden_access_not_allowed: 'Access to this content is prohibited',
    forbidden_user_access_not_allowed: 'User cannot access this content',
    unauthorized_invalid_appKey: 'Unauthorized access: default by AppKey',
    user_with_this_email_already_exists: 'Unable to create a patient: The chosen email is already used for another user. Please create a new user manually or edit it',
    error_on_updating_patient: 'An error occurred while updating the patient',
    forbidden_query_from_different_account: 'You are making the request from an account that is not the one expected. Thank you for reconnecting',
    generic_error_contact: 'contact.easyconsent@docaposte.fr',
    generic_error_subject: 'error',
    generic_error_message: 'error',
    generic_error_contact_message: 'If you encounter an error, please contact your support team:',
    not_allowed_by_cors: 'The request is not authorized from this station, please contact the Easy Consent technical support team',
    connector_forbidden_access_not_allowed: 'Connector: Access to content is prohibited',
    connector_forbidden_user_access_not_allowed: 'Connector: User cannot access this content',
    connector_forbidden_query_from_different_account: 'Connector: You are making the request from an account that is not the one expected. Thank you for reconnecting',
    connector_forbidden: 'Connector: Access not_allowed',
    connector_unauthorized_invalid_appKey: 'Connector: Unauthorized access due to an AppKey default',
    connector_forbidden_access_path: 'Connector: access to the requested route is not_allowed with your profile',
    connector_forbidden_medic_is_not_from_clinic: 'Connector: The account is not linked to the clinic. Please contact an administrator',
    mail_error_invalid_email: 'The email provided is not valid',
    sms_error_invalid_email: 'The phone number provided is not valid',
    survey_is_already_countersigned: 'This form is already signed',
    default_form_data: {
      title: null,
      step: { title: 'Stage title' },
      questions: {
        multiple_choice: {
          question: 'Your Multiple Choice Question',
          list: { firstChoice: 'First choice' },
        },
        text_answer: { question: 'Your Text Question' },
        dropdown_list: {
          question: 'Your Question Drop Down Menu',
          list: { firstChoice: 'First choice' },
        },
        pdf: { question: 'Your question PDF' },
        checkbox_full: {
          question: 'Your question check boxes',
          list: { firstChoice: 'First choice' },
        },
        validation: {
          question: 'Your Validation Question',
          data: {
            first: 'Yes, I understand',
            second: 'No, I want additional information',
          },
        },
      },
    },
    link_is_expired: 'Your link has expired. Thank you for reconnecting',
    errors: {
      too_many_attempts: 'Too many attempts. Your account has been blocked.',
      not_allowed: 'Incorrect password and/or login.',
    },
    options: 'Options',
    medic_title: {
      dr: 'Dr.',
      pr: 'Pr.',
      mr: 'MR',
      ms: 'mrs',
    },
  },
  error: 'Error',
  'too_many_attempt_please_try_again_later': 'You have reached the message sending limit. Please try again later.',
  'too_many_user_matching_query': 'Please contact the technical service.',
  'error_with_code': 'An error occured. Please try again later or contact support.',
  'missing_a_pro_phone_number': 'Please contact your investigator as its mobile number is missing.',
  already_signed: 'Document already signed, document is now available for reading',
};
