
import { connect } from 'react-redux';

import { MedicTemplateActions } from '../../../../redux/medicTemplates';
import { SurveyActions } from '../../../../redux/survey';
import CreateForm from './CreateForm';

const mapStateToProps = ({ auth, survey, medic, medicTemplate }) => {
  const currentMedic = medic.currentMedic;

  return {
    token: auth.token,
    currentMedic: currentMedic,
    selectedTeam: medic.selectedTeam,
    medicTeam: medic.medicalTeamList,
    questionTypes:  survey.questionTypes || {},
    newMedicTemplateId: medicTemplate.newMedicTemplateId,
  };
};

const mapDispatchToProps = (dispatch) => ({

  surveyCurrentDelete: () => dispatch(SurveyActions.surveyCurrentDelete()),
  templateUpsertRequest: (template) => dispatch(MedicTemplateActions.medicTemplateUpsertRequest(template)),
  clearNewMedicTemplateId: () => dispatch(MedicTemplateActions.clearNewMedicTemplateId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
